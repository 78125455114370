import React from 'react';

import Header from '../partials/Header';
import Footer from '../partials/Footer';

function PageNotFound(props) {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header user={props.user}/>

            <main
                className="min-h-full bg-cover py-16"
                style={{
                    backgroundImage:
                        'url("https://images.unsplash.com/photo-1555581064-8ce820e50679?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80")',
                }}
            >
                <div className="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
                    <div className="py-16">
                        <p className="font-semibold text-signature-900 uppercase tracking-wide">404
                            error</p>
                        <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl pt-6">
                            Uh oh! I think you’re lost.
                        </h1>
                        <p className="mt-2 text-lg font-medium text-dark text-opacity-50">
                            It looks like the page you’re looking for doesn't exist.
                        </p>
                        <div className="mt-6 py-12">
                            <a
                                href="/"
                                className="px-5 inline-flex items-center px-4 py-2 border border-transparent font-medium rounded-md text-black text-opacity-75 bg-signature-900 sm:hover:bg-opacity-50"
                            >
                                Go back home
                            </a>
                        </div>
                    </div>
                </div>
            </main>

            {/*  Site footer */}
            <Footer/>

        </div>
    );
}

export default PageNotFound;
