import React from 'react';

import Header from '../partials/Header';
import {Disclosure} from '@headlessui/react'
import {ChevronDownIcon, PlusIcon, MinusIcon} from '@heroicons/react/outline'
import Footer from '../partials/Footer';

const faqs = [
    {
        title: "MetaDragonz",
        questions: [
            {
                question: "What is MetaDragonz ser?",
                answer: {
                    __html:
                        "MetaDragonz is the very first play to earn NFT video game where the prizes, rewards, and the rankings are completely controlled by a smart contract.\n\n" +
                        "This is the future of gaming, and ArcadeNFTs are continuing to pioneer this space.\n\n" +
                        "We started making history in August of 2020 with the release of the first ever NFT video game on-chain, our Genesis Pinball NFT, and we are excited to announce the release of the MetaDragonz which is the first game to launch in our proprietary Arcade Arena. The Arcade Arena is a first of its kind smart-contract created specifically for p2e and pvp gaming in the metaverse where ranking, prizes, and payments will all be controlled and fulfilled entirely by the smart-contract.\n" +
                        "\n" + "Learn all there is to know at <a style='text-decoration: underline' href='https://metadragonz.com' target='_blank'>metadragonz.com</a>"
                }
            },
        ]
    },
    {
        title: "Arcade Genesis",
        questions: [
            {
                question: "What is the Genesis Pinball NFT?",
                answer: {
                    __html:
                        "The genesis pinball NFT is the first NFT released by ArcadeNFT. There are 500 total genesis pinball NFTs minted at an original minting price of .089 eth. There will not be any more genesis pinball machines released in the future.",
                }
            },
            {
                question: "How can I buy a Genesis Pinball NFT?",
                answer: {
                    __html:
                        "The minting has been concluded for the Genesis Pinball NFT, but they are available for purchase on the secondary market (OpenSea). You can see the pinball machines for sale in this link: <a style='text-decoration: underline' href='https://bit.ly/3CDGVKB' target='_blank'>https://bit.ly/3CDGVKB</a>",
                }
            },
            {
                question: "What are the benefits of holding a Genesis Pinball NFT?",
                answer: {
                    __html:
                        "The genesis pinball NFT is essentially the original building block of the project, and the team has built unmatched utility around the pinball machine. The utility of holding a Genesis Pinball NFT is listed below:\n" +
                        "\n" +
                        "1. Genesis holders will receive one free airdrop for each pinball machine they hold on all Arcade NFTs ever released for life (Some future game drops will be limited to 500 games meaning the only way to obtain these limited games is to be a genesis pinball holder)\n" +
                        "\n" +
                        "2. Genesis holders will receive additional utility in future drops using the arcade arena contract that will enable renting/staking functionality. More detail to come as the arcade arena contract becomes finalized\n" +
                        "\n" +
                        "3. Genesis holders will also be included in the ArcadeDAO (decentralized autonomous organization), which will be used to discuss the overall project development and potentially vote on important decisions as a community. The DAO just launched a voting & proposal system here: https://snapshot.org/#/arcadenfts.eth\n\n" +
                        "Read the roadmap released by the ArcadeNFT team to get more details here: <a style='text-decoration: underline' href='https://bit.ly/3znv1Te' target='_blank'>https://bit.ly/3znv1Te</a>",
                }
            },
            {
                question: "How do I join the DAO?",
                answer: {
                    __html:
                        "<a style='text-decoration: underline' href='https://snapshot.org/#/arcadenfts.eth' target='_blank'>https://snapshot.org/#/arcadenfts.eth</a>",
                }
            },
            {
                question: "Are the Genesis Pinball NFTs all the same?",
                answer: {
                    __html:
                        "Yes, the pinball machines are all the same. They are not assigned rarity traits, so no machine is rarer than the other. They all provide the same utility.\n" +
                        "\n" +
                        "Need more depth, read up on medium article:\n" +
                        "<a style='text-decoration: underline' href='https://medium.com/@arcadenfts/the-genesis-pinball-nft-5050a28bcfea' target='_blank'>https://medium.com/@arcadenfts/the-genesis-pinball-nft-5050a28bcfea</a>",
                }
            },
        ],
    },
    {
        title: "Arcade Classic",
        questions: [
            {
                question: "What is the Arcade Classic NFT?",
                answer: {
                    __html:
                        "The arcade classic NFT is the second NFT released by ArcadeNFT, and the game played within the NFT is similar to on of the most classic arcade games. There are 5,555 total arcade classic NFTs minted, at an original minting price of .075 eth.",
                }
            },
            {
                question: "How can I buy an Arcade Classic NFT?",
                answer: {
                    __html:
                        "The minting has concluded for the Arcade Classic NFT, but they are available for purchase on the secondary market (OpenSea). You can see the arcade classic machines for sale in this link: <a style='text-decoration: underline' href='https://bit.ly/3zJe85N' target='_blank'>https://bit.ly/3zJe85N</a>",
                }
            },
            {
                question: "How do I play my Arcade Classic NFT?",
                answer: {
                    __html:
                        "f you are viewing any arcade classics NFT on opensea (make sure that the ArcadeNFT account is OpenSea verified with a blue check), you can click on the NFT image to start the game. Once you are in the game, you can use WASD to move your character. Additionally, if you own an Arcade Classic NFT, you can play right here on the website to participate in any tournaments and save your high score.\n" +
                        "[Note: play on the PC with the chrome browser for best playing experience]\n",
                }
            },
            {
                question: "Which Arcade Classic NFT should I buy?",
                answer: {
                    __html:
                        "Each Arcade Classic NFT is unique in their own way, as they are randomly assigned rarity traits that determine how the NFT looks on the outside and on the inside when you are actually playing  your game. The rarity traits are purely aesthetic, so choose the Arcade Classic NFT that looks the best to you! See the #sneak-peeks channel in the discord server to see the different rarity traits, and the % of rarity compared to other traits.\n\n" +
                        "[Note: the only trait that could be advantageous is the speed boost trait, in which it makes your character move faster in game. This is more important for people who want to compete in the tournaments, as it will take a shorter time to reach a high score]\n",
                }
            },
        ]
    },
    {
        title: "ToadRunnerz x CrypToadz by GREMPLIN",
        questions: [
            {
                question: "What is the ToadRunnerz Arcade?",
                answer: {
                    __html:
                        "The Toad Runnerz arcade is a frogger-style arcade game built by ArcadeNFT in an official collaboration with Cryptoadz by Gremplin. The team at Cryptoadz provided some in-game assets, and ArcadeNFT brought the game to life. This is the third drop by ArcadeNFT and we are excited to merge our communities and start gaming!",
                }
            },
            {
                question: "Who were eligible for an airdrop?",
                answer: {
                    __html:
                        "1,777 of the total 4,777 toad runnerz supply was gifted as airdrops. The airdrop distribution was as follows:\n" +
                        "500 to genesis pinball holders (read more on pinballs here <a style='text-decoration: underline' href='https://medium.com/@arcadenfts/the-genesis-pinball-nft-5050a28bcfea' target='_blank'>https://medium.com/@arcadenfts/the-genesis-pinball-nft-5050a28bcfea</a>)" +
                        "500 to users affected by minting issues in arcade classic drop\n" +
                        "777 to the Cryptoadz community to distribute to toadz holders as they wish !vibe\n",
                }
            },
            {
                question: "How do I play my ToadRunnerz Arcade?",
                answer: {
                    __html:
                        "The Toad Runnerz Arcade is a fully playable in-wallet NFT, which means you can play directly from OpenSea even you don't own the NFT. Click on the NFT and use WASD to move your toad and complete the levels. Note: If you own the NFT, then play through the official ArcadeNFT website to play full screen for a better playing experience. Additionally, all future tournaments will be played through the ArcadeNFT website.",
                }
            },
            {
                question: "When is the ToadRunnerz tournament?",
                answer: {
                    __html:
                        "There are ongoing tournaments for ToadRunnerz owners at <a style='text-decoration: underline' href='https://metadragonz.com/play' target='_blank'>metadragonz.com</a>. Over 35 ETH has been rewarded in prize pools to date.",
                }
            },
            {
                question: "Where do I check my ToadRunnerz rarity ranking?",
                answer: {
                    __html:
                        "You can check the rarity ranking of your Toad Runnerz arcade here at <a style='text-decoration: underline' href='https://www.niftyriver.io/rarity/0x1e038a99aac19162633dcc4d215e5a27e6eb0355' target='_blank'>https://www.niftyriver.io/rarity/0x1e038a99aac19162633dcc4d215e5a27e6eb0355</a>\n\n" +
                        "You can also check your rarity score right here on the website under My Arcades\n",
                }
            },
        ]
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Help(props) {

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header user={props.user}/>

            {/*  Page content */}
            <main className="grow">
                <section className="relative">
                    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 pb-12">
                        <div className="max-w-5xl pt-32 md:pt-40 text-3xl mx-auto">
                            <h2 className="text-center uppercase pb-12 text-2xl sm:text-3xl" data-aos="fade-down"> <span
                                className="text-signature-900">Frequently asked</span><br />questions</h2>
                            <dl className="mt-6 divide-y divide-gray-600">
                                {faqs.map((faq) => (
                                    <div className="py-16">
                                        <h4 className="text-signature-900 text-sm uppercase">{faq.title}</h4>
                                        {faq.questions.map((q) => (
                                            <Disclosure as="div" key={q.question} className="pt-6">
                                                {({open}) => (
                                                    <>
                                                        <dt className="text-lg">
                                                            <Disclosure.Button
                                                                className="text-left w-full flex justify-between items-start text-gray-400">
                                                                <h3
                                                                    className="text-xl text-gray-900 dark:text-white">{q.question}</h3>
                                                                <span className="ml-6 h-7 flex items-center">
                                {!open && <PlusIcon
                                    className="h-6 w-6 transform"
                                    aria-hidden="true"
                                />}
                                                                    {open && <MinusIcon
                                                                        className="h-6 w-6 transform"
                                                                        aria-hidden="true"
                                                                    />}
                                    </span>
                                                            </Disclosure.Button>
                                                        </dt>
                                                        <Disclosure.Panel as="dd" className="mt-6">
                                                            <p style={{whiteSpace: "pre-line"}}
                                                               className="text-base text-gray-600 dark:text-gray-300"
                                                               dangerouslySetInnerHTML={q.answer}></p>
                                                        </Disclosure.Panel>
                                                    </>
                                                )}
                                            </Disclosure>
                                        ))}
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                </section>

            </main>

            {/*  Site footer */}
            <Footer/>

        </div>
    );
}

export default Help;
