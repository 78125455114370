import React from 'react';

import Header from '../partials/Header';
import Footer from '../partials/Footer';
import {Link} from "react-router-dom";

function Connect(props) {

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header user={props.user}/>

            {/*  Page content */}
            <main className="grow">

                <section className="relative">

                    <div className="relative max-w-7xl mx-auto px-4 sm:px-6">
                        <div className="pt-32 pb-10 md:pt-40 text-3xl text-center" data-aos="fade-down">
                            {!props.user.connected &&
                            <h2 className="text-center uppercase text-signature-900 dark:text-white"> <span
                                className="text-gray-900 dark:text-signature-900">Connect your</span><br/>Wallet</h2>}
                            {props.user.connected &&
                            <h5 className="text-center text-2xl mt-8 text-gray-900 dark:text-signature-900">{props.user.walletAddress.substring(0, 10) + "..." + props.user.walletAddress.substring(30)}</h5>}
                            {props.user.connected &&
                            <h5 className="text-center text-xl mt-1 text-gray-600 dark:text-white">{props.user.balance.substring(0, 5)}&nbsp;ETH</h5>}
                        </div>
                    </div>

                    {props.user.connected && <div className="relative max-w-6xl pb-20 mx-auto px-4 sm:px-6">
                        <div className="grid grid-cols-2 sm:grid-cols-4 gap-x-6 mt-10 m-auto border-gray-900 pb-20">
                            <div className="hidden sm:flex"/>
                            <Link to="my-arcades"
                                  className="rounded-full btn text-gray-900 bg-signature-900 hover:bg-signature-800 flex items-center">
                                <span>My Arcades</span>
                                <svg className="w-3 h-3 shrink-0 mt-px ml-2" viewBox="0 0 12 12"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path className="fill-current"
                                          d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z"/>
                                </svg>
                            </Link>
                            <button onClick={() => {
                                props.user.walletDisconnect(props.user);
                            }}
                                    className="rounded-full btn text-white bg-gray-900 dark:bg-gray-600 hover:bg-teal-400 flex items-center">
                                <span>Sign Out</span>
                                <svg className="w-3 h-3 shrink-0 mt-px ml-2" viewBox="0 0 12 12"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path className="fill-current"
                                          d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z"/>
                                </svg>
                            </button>
                            <div className="hidden sm:flex"/>
                        </div>
                    </div>}

                    {!props.user.connected &&
                    <div className="relative max-w-6xl pb-20 mx-auto px-4 sm:px-6">
                        <div
                            className="grid grid-cols-1 gap-y-3 sm:grid-cols-3 gap-x-3 items-stretch mt-10 m-auto border-gray-900 pb-20">
                            <div onClick={() => props.user.signInWithWalletLink()}
                                 className="max-w-xl cursor-pointer bg-stone-100 dark:bg-arcade-100 rounded justify-center items-center text-center flex flex-col py-8">
                                <img className="mb-6"
                                     src={require('../assets/images/walletlink.png').default} width="65"
                                     alt="Logo"/>
                                <div>
                                    <h4 className="uppercase text-xl pb-2">WalletLink</h4>
                                </div>
                            </div>
                            <div onClick={() => props.user.signInWithMetamask()}
                                 className="hidden bg-stone-100 dark:bg-arcade-100 px-10 rounded cursor-pointer justify-center items-center text-center sm:flex flex-col py-8">
                                <img className="mb-6"
                                     src={require('../assets/images/metamask.png').default} width="70"
                                     alt="Logo"/>
                                <div className="">
                                    <h4 className="uppercase text-xl pb-2">MetaMask</h4>
                                </div>
                            </div>
                            <div onClick={() => props.user.signInWithWalletConnect()}
                                 className="bg-stone-100 dark:bg-arcade-100 px-10 rounded cursor-pointer justify-center items-center text-center flex flex-col py-8">
                                <div><img className="mb-6"
                                          src={require('../assets/images/walletconnect.png').default} width="100"
                                          alt="Logo"/>
                                    <img className="sm:hidden"
                                         src={require('../assets/images/metamask.png').default} width="100"
                                         alt="Logo"/>
                                </div>
                                <div>
                                    <h4 className="uppercase text-xl pb-2 pt-6 sm:pt-0">WalletConnect</h4>
                                </div>
                            </div>
                        </div>
                    </div>}

                </section>


            </main>

            {/*  Site footer */}
            <Footer/>

        </div>
    );
}

export default Connect;
