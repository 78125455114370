import React, {useEffect, useState} from 'react';

import Header from '../partials/Header';
import Footer from '../partials/Footer';

import firebase from "firebase/compat";
import 'firebase/compat/firestore';
import Carousel from "../partials/Carousel";
import moment from "moment";
import ix_bg from "../assets/images/ix_bg.png";


function Home(props) {

    const [secondarySales, setSecondarySales] = useState([]);
    const [countdown, setCountDown] = useState("");


    useEffect(() => {
        /*const interval = setInterval(() => {
            const now = moment();
            const end = moment("2022-04-14T16:00:00Z");
            const diff = end.diff(now);
            const duration = moment.duration(diff);
            const formatted = duration.days() + "d " + duration.hours() + "h " + duration.minutes() + "m " + duration.seconds() + "s";
            setCountDown(formatted);
        }, 1000);
        getSecondarySales();
        return () => clearInterval(interval);*/
    }, []);

    function getSecondarySales() {
        firebase.firestore().collection("Secondary_Sales").limit(6).get().then(data => {
            if (!data.empty) {
                let sales = [];
                data.forEach(sale => {
                    sales.push(sale.data());
                });
                setSecondarySales(sales);
            }
        });
    }

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header user={props.user}/>

            {/*  Page content */}
            <main className="grow">

                {/*<section style={{minHeight: "100%"}} className="relative">

                    <div className="relative max-w-6xl mx-auto px-4 sm:px-6 pt-12">
                        <div className="pt-32 md:pt-20 md:pb-8">
                            <div className="text-center">
                                <div className="relative flex justify-center items-center">
                                    <div className="relative flex-col flex items-center justify-center"
                                         data-aos="fade-up">
                                        <p className="stroke-fills space-mono text-center" style={{
                                            fontSize: "135%",
                                            color: "#F4472B",
                                            fontFamily: 'Encode Sans Expanded, sans-serif'
                                        }}>近日公開</p>
                                        <img src={require('../assets/images/metadragonz_banner_logo.png').default}
                                             width="70%" alt="Hero Banner"/>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </section>*/}

                <section className="max-w-6xl mx-auto pt-24">
                    <div className="p-2 m-4" style={{background: "#B2F501", height: "560px"}}>
                        <div className="min-h-full h-full flex flex-col items-center justify-center relative" style={{
                            backgroundImage: `url(${ix_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}>
                                <div
                                    className="pt-20 flex flex-col md:flex-row w-full justify-center items-center space-x-4">
                                    <img className="mb-6 h-8"
                                         src={require('../assets/images/arcade_logo.svg').default}
                                         alt="Arcade Logo"/>
                                    <img className="mb-6 h-8"
                                         src={require('../assets/images/x.svg').default}
                                         alt="Planet IX divider"/>
                                    <img className="mb-6 h-8"
                                         src={require('../assets/images/ix_logo.svg').default}
                                         alt="Planet IX Logo"/>
                                </div>
                                <div className="mt-2 flex flex-col space-y-4 text-center items-center justify-center">
                                    <p className="uppercase text-white font-semibold ix-banner-text">Play, trade and get rewards</p>
                                    <button onClick={() => {
                                        window.open("https://planetix.com/", "_blank")
                                    }}
                                            className="ix-button">
                                        <h5 className="uppercase text-gray-900">Join Planet IX</h5>
                                    </button>
                                </div>
                            <img className="absolute left-3 top-3 w-10"
                                 src={require('../assets/images/ix_banner_topleft.svg').default}
                                 alt="IX"/>
                            <img className="absolute left-3 bottom-3 w-6"
                                 src={require('../assets/images/ix_banner_bottomleft.svg').default}
                                 alt="IX"/>
                            <img className="absolute right-3 top-3 w-10"
                                 src={require('../assets/images/ix_banner_topright.svg').default}
                                 alt="IX"/>
                            <img className="absolute right-3 bottom-3 w-16"
                                 src={require('../assets/images/ix_banner_bottomright.svg').default}
                                 alt="IX"/>
                        </div>
                    </div>

                </section>

                <section className="relative pt-16">

                    <div
                        className="flex max-w-6xl flex-row grid sm:grid-cols-2 justify-center items-center mx-auto my-6 pb-12">
                        <div className="mx-12 pt-12 sm:pt-0">
                            <div className=" pb-10 text-2xl" data-aos="fade-down">
                                <h2 className="uppercase text-gray-900 dark:text-signature-900">We love arcade<br/><span
                                    className="dark:text-white">games & we love crypto</span></h2>
                            </div>
                            <p style={{whiteSpace: "pre-line"}} className="dark:text-gray-300">In late 2020 our team
                                started to put together ideas, sketches & conceptual drawings of the ultimate Classic
                                Arcade NFT Series.<br/><br/>After months of tech iterations, we're finally ready to
                                share
                                the
                                BETA-launch of our first series containing interactive NFTs with the broader
                                community.<br/><br/>Become a part of our journey.
                            </p>
                        </div>
                        <div className="order-first sm:order-2 mx-12 sm:mb-4 mb-0">
                            {props.user && props.user.randomToken &&
                                <div style={{borderRadius: 15}} className="overflow-hidden pb-2"
                                     key={props.user.randomToken.tokenId}>
                                    <iframe className="border-b-2 border-arcade-300" width="100%" height="430px"
                                            src={props.user.randomToken.animation_url}/>
                                    <div
                                        className="bg-gray-100 dark:bg-arcade-100 p-4 flex-row flex justify-between items-center">
                                        <div>
                                            <p className="text-sm dark:text-gray-400">Token
                                                ID: {props.user.randomToken.token_id}</p>
                                            <h5 className="uppercase text-gray-600 dark:text-white">{props.user.randomToken.name}</h5>
                                            <p className="text-sm dark:text-gray-400">#{props.user.randomToken.rarity_metadata.index} with
                                                score {props.user.randomToken.rarity_score.toFixed(2)}</p>
                                        </div>
                                        <div>
                                            <img className=""
                                                 src={require('../assets/images/arcade-play-logo-darkbg.svg').default}
                                                 width="40"
                                                 alt="Collage"/>
                                        </div>
                                    </div>
                                    <div style={{borderBottomLeftRadius: 15, borderBottomRightRadius: 15}}
                                         className="bg-gray-200 dark:bg-arcade-200 p-4 flex flex-row gap-4">
                                        {props.user.randomToken.name !== "Pinball" &&
                                            <a href={(props.user.randomToken.name === "Racing" || props.user.randomToken.name === "Desert" || props.user.randomToken.name === "Aquatic" || props.user.randomToken.name === "Space") ? "/toadrunnerz/traits-table" : "/classics/traits-table"}
                                               className="flex-row flex gap-2 items-center">
                                                <div
                                                    className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                                    aria-label="Traits Table">
                                                    <svg
                                                        className="w-9 h-9 p-2 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-white"
                                                        viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M11 13H5C4.73478 13 4.48043 12.8946 4.29289 12.7071C4.10536 12.5196 4 12.2652 4 12V4C4 3.73478 4.10536 3.48043 4.29289 3.29289C4.48043 3.10536 4.73478 3 5 3H11C11.2652 3 11.5196 3.10536 11.7071 3.29289C11.8946 3.48043 12 3.73478 12 4V12C12 12.2652 11.8946 12.5196 11.7071 12.7071C11.5196 12.8946 11.2652 13 11 13ZM5 4V12H11V4H5Z"/>
                                                        <path
                                                            d="M2 12H0V11H2V5H0V4H2C2.26522 4 2.51957 4.10536 2.70711 4.29289C2.89464 4.48043 3 4.73478 3 5V11C3 11.2652 2.89464 11.5196 2.70711 11.7071C2.51957 11.8946 2.26522 12 2 12Z"/>
                                                        <path
                                                            d="M16 12H14C13.7348 12 13.4804 11.8946 13.2929 11.7071C13.1054 11.5196 13 11.2652 13 11V5C13 4.73478 13.1054 4.48043 13.2929 4.29289C13.4804 4.10536 13.7348 4 14 4H16V5H14V11H16V12Z"/>
                                                    </svg>

                                                </div>
                                            </a>}
                                        <a target="_blank" rel="noreferrer"
                                           href={props.user.randomToken.name === "Desert" || props.user.randomToken.name === "Aquatic" || props.user.randomToken.name === "Racing" || props.user.randomToken.name === "Space" ? "https://rarity.tools/toadrunnerz/view/" + props.user.randomToken.token_id : "https://rarity.tools/arcadenft/view/" + props.user.randomToken.token_id}
                                           className="flex">
                                            <div
                                                className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                                aria-label="Rarity Tool">
                                                <svg
                                                    className="w-9 h-9 p-2 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-white"
                                                    viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M4.5 7C4.00555 7 3.5222 6.85338 3.11108 6.57867C2.69995 6.30397 2.37952 5.91352 2.1903 5.45671C2.00108 4.99989 1.95157 4.49723 2.04804 4.01228C2.1445 3.52732 2.3826 3.08187 2.73223 2.73223C3.08187 2.3826 3.52732 2.1445 4.01228 2.04804C4.49723 1.95157 4.99989 2.00108 5.45671 2.1903C5.91352 2.37952 6.30397 2.69995 6.57867 3.11108C6.85338 3.5222 7 4.00555 7 4.5C6.99927 5.16282 6.73565 5.79828 6.26696 6.26696C5.79828 6.73565 5.16282 6.99927 4.5 7ZM4.5 3C4.20333 3 3.91332 3.08797 3.66665 3.2528C3.41997 3.41762 3.22771 3.65189 3.11418 3.92598C3.00065 4.20007 2.97095 4.50167 3.02882 4.79264C3.0867 5.08361 3.22956 5.35088 3.43934 5.56066C3.64912 5.77044 3.91639 5.9133 4.20737 5.97118C4.49834 6.02906 4.79994 5.99935 5.07403 5.88582C5.34812 5.77229 5.58238 5.58003 5.74721 5.33336C5.91203 5.08668 6 4.79667 6 4.5C5.99956 4.10231 5.84139 3.72103 5.56018 3.43982C5.27897 3.15861 4.89769 3.00044 4.5 3Z"/>
                                                    <path
                                                        d="M13.293 2.00001L2 13.293L2.70704 14L14 2.70705L13.293 2.00001Z"/>
                                                    <path
                                                        d="M11.5 14C11.0055 14 10.5222 13.8534 10.1111 13.5787C9.69995 13.304 9.37952 12.9135 9.1903 12.4567C9.00108 11.9999 8.95157 11.4972 9.04804 11.0123C9.1445 10.5273 9.3826 10.0819 9.73223 9.73223C10.0819 9.3826 10.5273 9.1445 11.0123 9.04804C11.4972 8.95157 11.9999 9.00108 12.4567 9.1903C12.9135 9.37952 13.304 9.69995 13.5787 10.1111C13.8534 10.5222 14 11.0055 14 11.5C13.9993 12.1628 13.7356 12.7983 13.267 13.267C12.7983 13.7356 12.1628 13.9993 11.5 14ZM11.5 10C11.2033 10 10.9133 10.088 10.6666 10.2528C10.42 10.4176 10.2277 10.6519 10.1142 10.926C10.0007 11.2001 9.97095 11.5017 10.0288 11.7926C10.0867 12.0836 10.2296 12.3509 10.4393 12.5607C10.6491 12.7704 10.9164 12.9133 11.2074 12.9712C11.4983 13.0291 11.7999 12.9994 12.074 12.8858C12.3481 12.7723 12.5824 12.58 12.7472 12.3334C12.912 12.0867 13 11.7967 13 11.5C12.9996 11.1023 12.8414 10.721 12.5602 10.4398C12.279 10.1586 11.8977 10.0004 11.5 10Z"/>
                                                </svg>

                                            </div>
                                        </a>
                                        <a target="_blank" rel="noreferrer" href="https://metadragonz.com/play"
                                           className="flex-row flex gap-2 items-center">
                                            <div
                                                className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                                target="_blank" rel="noreferrer" href="#classics-rarity"
                                                aria-label="Tournament">
                                                <svg
                                                    className="w-9 h-9 p-2 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-white"
                                                    viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M13 3.5H12V3C11.9997 2.73488 11.8942 2.4807 11.7068 2.29323C11.5193 2.10576 11.2651 2.0003 11 2H5C4.73488 2.0003 4.4807 2.10576 4.29323 2.29323C4.10576 2.4807 4.0003 2.73488 4 3V3.5H3C2.73488 3.5003 2.4807 3.60576 2.29323 3.79323C2.10576 3.9807 2.0003 4.23488 2 4.5V6C2.0006 6.53025 2.2115 7.03861 2.58644 7.41356C2.96139 7.7885 3.46975 7.9994 4 8H4.161C4.36934 8.76537 4.79603 9.45351 5.38897 9.9804C5.98191 10.5073 6.71545 10.8501 7.5 10.967V13H5V14H11V13H8.5V10.9656C9.29474 10.8673 10.0414 10.5318 10.6426 10.0027C11.2437 9.47373 11.6715 8.77579 11.87 8H12C12.5303 7.9994 13.0386 7.7885 13.4136 7.41356C13.7885 7.03861 13.9994 6.53025 14 6V4.5C13.9997 4.23488 13.8942 3.9807 13.7068 3.79323C13.5193 3.60576 13.2651 3.5003 13 3.5ZM4 7C3.73488 6.9997 3.4807 6.89424 3.29323 6.70677C3.10576 6.5193 3.0003 6.26512 3 6V4.5H4V7ZM11 7C11 7.40177 10.9193 7.79945 10.7627 8.16944C10.6061 8.53942 10.3767 8.87418 10.0883 9.15385C9.79981 9.43352 9.45812 9.6524 9.08347 9.7975C8.70881 9.9426 8.30883 10.011 7.90725 9.99855C7.11101 9.94917 6.36447 9.59467 5.82296 9.00883C5.28146 8.42299 4.98669 7.65091 5 6.85325V3H11V7ZM13 6C12.9997 6.26512 12.8942 6.5193 12.7068 6.70677C12.5193 6.89424 12.2651 6.9997 12 7V4.5H13V6Z"/>
                                                </svg>
                                            </div>
                                        </a>
                                        <a target="_blank" rel="noreferrer"
                                           href={props.user.randomToken.name === "Desert" || props.user.randomToken.name === "Aquatic" || props.user.randomToken.name === "Racing" || props.user.randomToken.name === "Space" ? "https://etherscan.io/address/0x1e038A99AAC19162633Dcc4d215E5a27e6eB0355" : "https://etherscan.io/address/0xA0c38108bBB0F5f2fB46a2019D7314Cce38f3f22"}
                                           className="flex-row flex gap-2 items-center">
                                            <div
                                                className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                                aria-label="Smart Contract">
                                                <svg
                                                    className="w-9 h-9 p-1 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="-38.39985 -104.22675 332.7987 625.3605">
                                                    <path
                                                        d="M125.166 285.168l2.795 2.79 127.962-75.638L127.961 0l-2.795 9.5z"/>
                                                    <path d="M127.962 287.959V0L0 212.32z"/>
                                                    <path
                                                        d="M126.386 412.306l1.575 4.6L256 236.587l-128.038 75.6-1.575 1.92z"/>
                                                    <path d="M0 236.585l127.962 180.32v-104.72z"/>
                                                    <path d="M127.961 154.159v133.799l127.96-75.637z"/>
                                                    <path d="M127.96 154.159L0 212.32l127.96 75.637z"/>
                                                </svg>
                                            </div>
                                        </a>
                                        <a target="_blank" rel="noreferrer"
                                           href={props.user.randomToken.animation_url}
                                           className="flex-row flex gap-2 items-center">
                                            <div
                                                className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                                aria-label="IPFS Link">
                                                <svg
                                                    className="w-9 h-9 p-2 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-white"
                                                    role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                    <path
                                                        d="M12 0L1.608 6v12L12 24l10.392-6V6zm-1.073 1.445h.001a1.8 1.8 0 002.138 0l7.534 4.35a1.794 1.794 0 000 .403l-7.535 4.35a1.8 1.8 0 00-2.137 0l-7.536-4.35a1.795 1.795 0 000-.402zM21.324 7.4c.109.08.226.147.349.201v8.7a1.8 1.8 0 00-1.069 1.852l-7.535 4.35a1.8 1.8 0 00-.349-.2l-.009-8.653a1.8 1.8 0 001.07-1.851zm-18.648.048l7.535 4.35a1.8 1.8 0 001.069 1.852v8.7c-.124.054-.24.122-.349.202l-7.535-4.35a1.8 1.8 0 00-1.069-1.852v-8.7c.124-.054.24-.122.35-.202z"/>
                                                </svg>
                                            </div>
                                        </a>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </section>

                <section className="relative">

                    <div
                        className="flex max-w-6xl flex-row grid sm:grid-cols-2 justify-center items-center mx-auto my-6 pb-12">
                        <div className="mx-12">
                            {/*<img className="mb-12 sm:mb-0"
                                 src={require('../assets/images/Arcade_Big.gif').default} width="100%"
                                 alt="Collage"/>*/}
                            <iframe style={{borderRadius: 15}} className="mb-12 sm:mb-0"
                                    height="550px"
                                    src={"https://metadragonz.b-cdn.net/animations/b1/26MD121S204T/"} width="100%"
                            />

                        </div>
                        <div className="mx-12">
                            <div className="pb-10 text-2xl" data-aos="fade-down">
                                <h2 className="uppercase text-gray-900 dark:text-white">Bringing back<br/>retro</h2>
                            </div>
                            <p style={{whiteSpace: "pre-line"}} className="dark:text-gray-300">The first “solid state”
                                arcade machines with a primitive computer chip replaced the “stack” of rotating discs
                                appeared in the 80’s. This allowed the head artwork to expand, and, storytelling with
                                visuals & tunes became main driver to the pinballs popularity.
                                <br/><br/>
                                We are bringing new life into the pop culture genre by bridging the 80’s arcade
                                experience with the todays popular NFT collectables.
                                <br/><br/>
                                Join us on an adventure where we take the classical arcade games into the future
                                combined with tunes and art from new & famous creators.
                            </p>
                        </div>
                    </div>
                </section>

                {secondarySales.length > 0 &&
                    <Carousel className={"mt-8"} user={props.user} secondarySales={secondarySales}/>}

                <section className="relative pt-32">

                    <div className="w-full mx-auto">

                        {/* CTA box */}
                        <div className="relative py-10 px-8 md:py-32 md:px-12 overflow-hidden">

                            {/* Background illustration */}
                            <div className="absolute inset-0 min-h-full pt-16 box-content -z-1">
                                <img className="absolute inset-0 w-full h-full object-cover overflow-hidden"
                                     src={require('../assets/images/arcade_cta_image.png').default} width="100%"
                                     alt="Background"/>
                            </div>

                            <div className="max-w-6xl mx-auto">
                                <div className="max-w-3xl md:ml-3">
                                    {/* CTA header */}
                                    <h3 className="mb-2 uppercase text-2xl text-white text-center sm:text-left">Join the
                                        community</h3>
                                    <p className="text-gray-300 text-center sm:text-left">Arcade NFT Discord already has
                                        over 5000 family
                                        members.
                                        If you want to join the #ARCADENFT it’s here. Join us to get the news as soon as
                                        possible, follow our latest announcements & be ready for tournaments where you
                                        can compete for money</p>
                                    {/* CTA button */}
                                    <div className="flex mt-8 justify-center sm:justify-start">
                                        <ul className="flex items-center gap-6 pt-6">
                                            <li className="rounded-full">
                                                <a style={{
                                                    fontWeight: "700",
                                                    background: "linear-gradient(95.41deg, rgba(114, 137, 218, 0.25) 0%, rgba(168, 187, 255, 0.25) 100%)",
                                                }} target="_blank" rel="noreferrer"
                                                   href="https://discord.com/invite/CFqcYg3Zye"
                                                   className="rounded-full px-4 py-2 text-white font-medium text-center">Join
                                                    Discord</a>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </main>

            {/*  Site footer */}
            <Footer/>

        </div>
    );
}

export default Home;
