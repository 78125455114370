import React, {useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Dropdown from '../utils/Dropdown';
import Transition from '../utils/Transition.js';

function Header(props) {

    const [mobileNavOpen, setMobileNavOpen] = useState(false);

    const trigger = useRef(null);
    const mobileNav = useRef(null);

    // close the mobile menu on click outside
    useEffect(() => {
        const clickHandler = ({target}) => {
            if (!mobileNav.current || !trigger.current) return;
            if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
            setMobileNavOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close the mobile menu if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({keyCode}) => {
            if (!mobileNavOpen || keyCode !== 27) return;
            setMobileNavOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    // Handle light modes
    const [darkMode, setDarkMode] = useState(() => {
        const dark = localStorage.getItem('dark-mode');
        if (dark === null) {
            return true;
        } else {
            return dark === 'true';
        }
    });

    useEffect(() => {
        localStorage.setItem('dark-mode', darkMode)
        if (darkMode) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }, [darkMode]);

    return (
        <header className="absolute w-full z-30">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="flex items-center justify-between h-20">

                    {/* Site branding */}
                    <div className="shrink-0 mr-5">
                        {/* Logo */}
                        <Link to="/" className="block" aria-label="Home">
                            <img className="hidden dark:block"
                                 src={require('../assets/images/arcade-animated-play-logo-darkbg.gif').default}
                                 width="120" alt="Logo"/>
                            <img className="block dark:hidden"
                                 src={require('../assets/images/arcade-play-logo-darkbg.svg').default}
                                 width="60" alt="Logo"/>
                        </Link>
                    </div>

                    {/* Desktop navigation */}
                    <nav className="hidden lg:flex lg:grow">

                        {/* Desktop menu links */}
                        <ul className="flex grow flex-wrap items-center justify-end font-medium">
                            <li>
                                <Link to="/my-arcades"
                                      className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">My
                                    Arcades</Link>
                            </li>
                            <li>
                                <a target="_blank" rel="noreferrer" href="https://metadragonz.com/play"
                                   className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">Tournaments</a>
                            </li>
                            <li>
                                <Link to="/drops"
                                      className="active:text-signature-900 text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">Drops</Link>
                            </li>
                            {/* Menu with dropdown */}
                            <Dropdown title="Secondary">
                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/arcadenft?search[query]=Pinball"
                                       className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">Genesis</a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/arcadenft"
                                       className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">Classics</a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/toadrunnerz"
                                       className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">Toadrunnerz</a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/metadragonz"
                                       className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">Metadragonz</a>
                                </li>
                            </Dropdown>
                            <li>
                                <Link to="/about"
                                      className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">About</Link>
                            </li>
                            <li>
                                <Link to="/contact"
                                      className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">Contact</Link>
                            </li>
                        </ul>

                        {/* Desktop CTA on the right */}
                        <ul className="flex justify-end flex-wrap items-center">
                            <li className="rounded-full">
                                {!props.user.connected && <Link to="/connect"
                                                                className=" rounded-full dark:border-gray-600 px-4 py-2 text-white bg-gray-600 hover:bg-signature-900 hover:text-gray-900 ml-6">Connect
                                    Wallet</Link>}
                                {props.user.connected && <Link to="/connect"
                                                               className="rounded-full border-2 border-signature-900 dark:border-signature-900 px-4 py-2 text-gray-900 dark:text-gray-900 bg-signature-900 ml-6">{props.user.walletAddress.substring(0, 8) + "..." + props.user.walletAddress.substring(34)}
                                </Link>}
                            </li>
                        </ul>

                    </nav>


                    {/*************************************************  Mobile Menu  ***************************************************/}

                    <div className="inline-flex lg:hidden">

                        {/* Hamburger button */}
                        <button ref={trigger} className={`hamburger ${mobileNavOpen && 'active'}`}
                                aria-controls="mobile-nav" aria-expanded={mobileNavOpen}
                                onClick={() => setMobileNavOpen(!mobileNavOpen)}>
                            <span className="sr-only">Menu</span>
                            <svg
                                className="w-6 h-6 fill-current text-gray-900 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 transition duration-150 ease-in-out"
                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <rect y="4" width="24" height="2" rx="1"/>
                                <rect y="11" width="24" height="2" rx="1"/>
                                <rect y="18" width="24" height="2" rx="1"/>
                            </svg>
                        </button>

                        {/*Mobile navigation */}
                        <Transition
                            show={mobileNavOpen}
                            tag="ul"
                            className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg"
                            enter="transition ease-out duration-200 transform"
                            enterStart="opacity-0 -translate-x-full"
                            enterEnd="opacity-100 translate-x-0"
                            leave="transition ease-out duration-200"
                            leaveStart="opacity-100"
                            leaveEnd="opacity-0"
                        >
                            <nav id="mobile-nav" ref={mobileNav}
                                 className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg no-scrollbar">
                                <div className="py-6 pr-4 pl-20">
                                    {/* Logo */}
                                    <Link to="/" className="block" aria-label="Home">
                                        <img className="hidden dark:block"
                                             src={require('../assets/images/arcade-animated-play-logo-darkbg.gif').default}
                                             width="100" alt="Logo"/>
                                        <img className="block dark:hidden pb-8"
                                             src={require('../assets/images/arcade-play-logo-darkbg.svg').default}
                                             width="50" alt="Logo"/>
                                    </Link>
                                    {/* Links */}
                                    <ul>
                                        <li>
                                            <Link to="/my-arcades"
                                                  className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">My
                                                Arcades</Link>
                                        </li>
                                        <li>
                                            <a target="_blank" rel="noreferrer" href="https://metadragonz.com/play"
                                               className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">Tournaments</a>
                                        </li>
                                        <li>
                                            <Link to="/drops"
                                                  className="active:text-signature-900 text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">Drops</Link>
                                        </li>
                                        <li>
                                            <a target="_blank" rel="noreferrer"
                                               href="https://opensea.io/collection/arcadenft"
                                               className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">OpenSea</a>
                                        </li>
                                        <li>
                                            <Link to="/about"
                                                  className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">About</Link>
                                        </li>
                                        <li>
                                            <Link to="/help"
                                                  className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">FAQ</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact"
                                                  className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">Contact</Link>
                                        </li>
                                    </ul>
                                    <ul className="flex justify-start flex-wrap pt-6">
                                        <li className="rounded-full">
                                            {!props.user.connected && <Link to="/connect"
                                                                            className=" rounded-full dark:border-gray-600 px-4 py-2 text-white bg-gray-600 hover:bg-signature-900 hover:text-gray-900 ml-3">Connect
                                                Wallet</Link>}
                                            {props.user.connected && <Link to="/connect"
                                                                           className="rounded-full border-2 border-signature-900 dark:border-signature-900 px-4 py-2 text-gray-900 dark:text-gray-900 bg-signature-900 ml-3">{props.user.walletAddress.substring(0, 8) + "..." + props.user.walletAddress.substring(34)}</Link>}
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </Transition>

                    </div>

                </div>
            </div>
        </header>
    );
}

export default Header;
