import React from 'react';

import Header from '../partials/Header';
import Footer from '../partials/Footer';
import ReactPlayer from 'react-player';
import cryptowave from '../assets/videos/arcade_cryptowave.m4v';
import changa from '../assets/videos/arcade_green-classic.mp4';
import genesis_promo from '../assets/videos/genesis_promo.mp4';
import classics_promo from '../assets/videos/classics_promo.mp4';
import toadrunnerz_promo from '../assets/videos/toadrunnerz_promo.mp4';
import toadrunnerz from '../assets/videos/toadrunnerz.mp4';
import metadragonz from '../assets/videos/metadragonz_promo.mp4';
import {Link} from "react-router-dom";


function Drops(props) {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header user={props.user}/>

            {/*  Page content */}
            <main className="grow pb-20">

                {/*  Page sections  */}
                <section className="relative">
                    <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                        <div className="pt-32 pb-20 md:pt-40 text-3xl text-center" data-aos="fade-down">
                            <h2 className="text-center uppercase text-gray-900 dark:text-signature-900">Our Drops</h2>
                            <p className="text-center uppercase text-sm text-gray-900 dark:text-white">Click & Hit enter to play</p>
                        </div>
                    </div>
                </section>

                <section id="metadragonz" className="relative pb-16">

                    <div className="max-w-6xl mx-auto px-5">

                        <div
                            style={{borderTopLeftRadius: 15, borderTopRightRadius : 15}} className="shadow shadow-2xl bg-gray-100 dark:bg-arcade-100 p-8 flex-col sm:flex-row flex items-start gap-6">
                            <div className="w-full sm:w-1/2">
                                <p className="text-sm dark:text-gray-500">Drop #4 2022.04.14</p>
                                <h2 className="uppercase text-2xl">MetaDragonz</h2>
                                <p className="dark:text-gray-300 mt-8">
                                    Click artwork to play!
                                    <br/><br/>
                                    MetaDragonz is the very first play to earn NFT video game where the prizes, rewards,
                                    and the rankings are completely controlled by a smart contract.
                                    This is the future of gaming, and ArcadeNFTs are continuing to pioneer this
                                    space.<br/><br/>
                                    We started making history in August of 2020 with the release of the first ever NFT
                                    video game on-chain, our Genesis Pinball NFT, and we are excited to announce the
                                    release of the MetaDragonz which is the first game to launch in our proprietary
                                    Arcade Arena. <br/><br/>The Arcade Arena is a first of its kind smart-contract
                                    created specifically for p2e and pvp gaming in the metaverse where ranking, prizes,
                                    and payments are all controlled and fulfilled entirely by a smart contract.
                                </p>
                            </div>
                            <div className="w-full sm:w-1/2 gap-2">
                                {/*<iframe className="ml-0 sm:ml-10 sm:pt-0 rounded" style={{borderRadius: 15, overflow: "hidden"}} width="90%" height="550px" src="https://metadragonz.b-cdn.net/animations/b1/26MD121S204T/" />*/}
                                <iframe className="ml-0 sm:ml-10 sm:pt-0 rounded" style={{borderRadius: 15, overflow: "hidden"}} width="90%" height="550px" src="https://metadragonz.b-cdn.net/animations/b1/TUNGSTEN/" />

                                {/*<div onClick={() => {
                                    //if(window.innerWidth > 1000) {
                                        window.open("https://metadragonz.com", "_blank")
                                    //}
                                }} style={{borderRadius: 15, overflow: "hidden"}}
                                     className="ml-0 sm:ml-10 sm:pt-0 rounded mt-6">
                                    <ReactPlayer width='400px'
                                                 height='400px' loop muted autoplay playing url={metadragonz}/>
                                </div>*/}
                            </div>
                        </div>
                        <div style={{borderBottomLeftRadius: 15, borderBottomRightRadius : 15}}
                            className="shadow shadow-2xl bg-gray-200 dark:bg-arcade-200 p-8 flex-row flex items-center justify-center">
                            <ul className="flex items-center gap-6">
                                <li className="rounded-full">
                                    <a target="_blank" rel="noreferrer"
                                       href="https://metadragonz.com/"
                                       className="rounded-full  dark:text-gray-900 px-4 py-2 dark:text-signature-900 bg-signature-900 border-gray-900 dark:hover:bg-signature-900 dark:hover:text-gray-900 border-2 dark:border-signature-900 mt-8">Learn
                                        More</a>
                                </li>
                                <li className="hidden sm:block rounded-full">
                                    <a target="_blank" rel="noreferrer"
                                       href="https://metadragonz.com"
                                       className="animate-pulse rounded-full px-4 py-2 dark:text-signature-900 border-gray-900 dark:hover:bg-signature-900 dark:hover:text-gray-900 border-2 dark:border-signature-900 mt-8">Minting now</a>
                                </li>
                            </ul>
                        </div>

                    </div>

                </section>

                <section id="toadrunnerz" className="relative pb-16">

                    <div className="max-w-6xl mx-auto px-5">

                        <div style={{borderTopLeftRadius: 15, borderTopRightRadius : 15}}
                            className="shadow shadow-2xl bg-gray-100 dark:bg-arcade-100 p-8 flex-col sm:flex-row flex items-start gap-6">
                            <div className="w-full sm:w-1/2 pb-4">
                                <p className="text-sm dark:text-gray-500">Drop #3</p>
                                <h2 className="uppercase text-2xl">ToadRunnerz</h2>
                                <p className="dark:text-gray-300 mt-8">
                                    Cryptoadz are small amphibious creatures that roam the swampy basin of what was
                                    formerly known as Uniswamp. Prior to minting, the Evil King Gremplin captured and
                                    exiled their leader, Colonel Floorbin, declaring his divine right to rule over them
                                    in the metaverse. This event became known as the “THE CROAKENING.” The original
                                    CrypToadz minting freed all 6969 CrypToadz from King Gremplin’s rule.
                                    <br/><br/>
                                    A part of the CryptToadz story is now told in our game entitled Toad Runnerz.
                                    Contrary to popular belief, the metaverse is no vacation picnic with only rainbows
                                    and daisies. Now that the Toadz are free to roam the metaverse, they now must cross
                                    deserts dodging tumbleweeds, race over highways avoiding billboards, and swim
                                    through the sea avoiding sharks to make it home. It is now up to their owners to
                                    guide through these obstacles, all while competing with other game owners for high
                                    scores!
                                    <br/><br/>
                                    Drop Count: 4777 <br/>
                                    1280 different rarity traits<br/>
                                    Sold out
                                </p>
                                <div className="mt-8 grid grid-cols-2 gap-8">
                                    <a href="/toadrunnerz/traits-table"
                                       className="flex-row flex gap-2 items-center">
                                        <div
                                            className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                            aria-label="Traits Table">
                                            <svg
                                                className="w-8 h-8 p-2 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-white"
                                                viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11 13H5C4.73478 13 4.48043 12.8946 4.29289 12.7071C4.10536 12.5196 4 12.2652 4 12V4C4 3.73478 4.10536 3.48043 4.29289 3.29289C4.48043 3.10536 4.73478 3 5 3H11C11.2652 3 11.5196 3.10536 11.7071 3.29289C11.8946 3.48043 12 3.73478 12 4V12C12 12.2652 11.8946 12.5196 11.7071 12.7071C11.5196 12.8946 11.2652 13 11 13ZM5 4V12H11V4H5Z"/>
                                                <path
                                                    d="M2 12H0V11H2V5H0V4H2C2.26522 4 2.51957 4.10536 2.70711 4.29289C2.89464 4.48043 3 4.73478 3 5V11C3 11.2652 2.89464 11.5196 2.70711 11.7071C2.51957 11.8946 2.26522 12 2 12Z"/>
                                                <path
                                                    d="M16 12H14C13.7348 12 13.4804 11.8946 13.2929 11.7071C13.1054 11.5196 13 11.2652 13 11V5C13 4.73478 13.1054 4.48043 13.2929 4.29289C13.4804 4.10536 13.7348 4 14 4H16V5H14V11H16V12Z"/>
                                            </svg>

                                        </div>
                                        <p className="text-sm hover:underline">Traits Table</p>
                                    </a>
                                    <a target="_blank" rel="noreferrer" href="https://rarity.tools/toadrunnerz"
                                       className="flex-row flex gap-2 items-center">
                                        <div
                                            className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                            aria-label="Rarity Tool">
                                            <svg
                                                className="w-8 h-8 p-2 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-white"
                                                viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.5 7C4.00555 7 3.5222 6.85338 3.11108 6.57867C2.69995 6.30397 2.37952 5.91352 2.1903 5.45671C2.00108 4.99989 1.95157 4.49723 2.04804 4.01228C2.1445 3.52732 2.3826 3.08187 2.73223 2.73223C3.08187 2.3826 3.52732 2.1445 4.01228 2.04804C4.49723 1.95157 4.99989 2.00108 5.45671 2.1903C5.91352 2.37952 6.30397 2.69995 6.57867 3.11108C6.85338 3.5222 7 4.00555 7 4.5C6.99927 5.16282 6.73565 5.79828 6.26696 6.26696C5.79828 6.73565 5.16282 6.99927 4.5 7ZM4.5 3C4.20333 3 3.91332 3.08797 3.66665 3.2528C3.41997 3.41762 3.22771 3.65189 3.11418 3.92598C3.00065 4.20007 2.97095 4.50167 3.02882 4.79264C3.0867 5.08361 3.22956 5.35088 3.43934 5.56066C3.64912 5.77044 3.91639 5.9133 4.20737 5.97118C4.49834 6.02906 4.79994 5.99935 5.07403 5.88582C5.34812 5.77229 5.58238 5.58003 5.74721 5.33336C5.91203 5.08668 6 4.79667 6 4.5C5.99956 4.10231 5.84139 3.72103 5.56018 3.43982C5.27897 3.15861 4.89769 3.00044 4.5 3Z"/>
                                                <path
                                                    d="M13.293 2.00001L2 13.293L2.70704 14L14 2.70705L13.293 2.00001Z"/>
                                                <path
                                                    d="M11.5 14C11.0055 14 10.5222 13.8534 10.1111 13.5787C9.69995 13.304 9.37952 12.9135 9.1903 12.4567C9.00108 11.9999 8.95157 11.4972 9.04804 11.0123C9.1445 10.5273 9.3826 10.0819 9.73223 9.73223C10.0819 9.3826 10.5273 9.1445 11.0123 9.04804C11.4972 8.95157 11.9999 9.00108 12.4567 9.1903C12.9135 9.37952 13.304 9.69995 13.5787 10.1111C13.8534 10.5222 14 11.0055 14 11.5C13.9993 12.1628 13.7356 12.7983 13.267 13.267C12.7983 13.7356 12.1628 13.9993 11.5 14ZM11.5 10C11.2033 10 10.9133 10.088 10.6666 10.2528C10.42 10.4176 10.2277 10.6519 10.1142 10.926C10.0007 11.2001 9.97095 11.5017 10.0288 11.7926C10.0867 12.0836 10.2296 12.3509 10.4393 12.5607C10.6491 12.7704 10.9164 12.9133 11.2074 12.9712C11.4983 13.0291 11.7999 12.9994 12.074 12.8858C12.3481 12.7723 12.5824 12.58 12.7472 12.3334C12.912 12.0867 13 11.7967 13 11.5C12.9996 11.1023 12.8414 10.721 12.5602 10.4398C12.279 10.1586 11.8977 10.0004 11.5 10Z"/>
                                            </svg>

                                        </div>
                                        <p className="text-sm hover:underline">Rarity Tool</p>
                                    </a>
                                    <a href="https://app.arcadenfts.com/" target="_blank" rel="noreferrer"
                                       className="flex-row flex gap-2 items-center">
                                        <div
                                            className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                            aria-label="Tournament">
                                            <svg
                                                className="w-8 h-8 p-2 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-white"
                                                viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M13 3.5H12V3C11.9997 2.73488 11.8942 2.4807 11.7068 2.29323C11.5193 2.10576 11.2651 2.0003 11 2H5C4.73488 2.0003 4.4807 2.10576 4.29323 2.29323C4.10576 2.4807 4.0003 2.73488 4 3V3.5H3C2.73488 3.5003 2.4807 3.60576 2.29323 3.79323C2.10576 3.9807 2.0003 4.23488 2 4.5V6C2.0006 6.53025 2.2115 7.03861 2.58644 7.41356C2.96139 7.7885 3.46975 7.9994 4 8H4.161C4.36934 8.76537 4.79603 9.45351 5.38897 9.9804C5.98191 10.5073 6.71545 10.8501 7.5 10.967V13H5V14H11V13H8.5V10.9656C9.29474 10.8673 10.0414 10.5318 10.6426 10.0027C11.2437 9.47373 11.6715 8.77579 11.87 8H12C12.5303 7.9994 13.0386 7.7885 13.4136 7.41356C13.7885 7.03861 13.9994 6.53025 14 6V4.5C13.9997 4.23488 13.8942 3.9807 13.7068 3.79323C13.5193 3.60576 13.2651 3.5003 13 3.5ZM4 7C3.73488 6.9997 3.4807 6.89424 3.29323 6.70677C3.10576 6.5193 3.0003 6.26512 3 6V4.5H4V7ZM11 7C11 7.40177 10.9193 7.79945 10.7627 8.16944C10.6061 8.53942 10.3767 8.87418 10.0883 9.15385C9.79981 9.43352 9.45812 9.6524 9.08347 9.7975C8.70881 9.9426 8.30883 10.011 7.90725 9.99855C7.11101 9.94917 6.36447 9.59467 5.82296 9.00883C5.28146 8.42299 4.98669 7.65091 5 6.85325V3H11V7ZM13 6C12.9997 6.26512 12.8942 6.5193 12.7068 6.70677C12.5193 6.89424 12.2651 6.9997 12 7V4.5H13V6Z"/>
                                            </svg>
                                        </div>
                                        <p
                                            className="text-sm hover:underline">Tournament</p>
                                    </a>
                                    <a href="https://etherscan.io/address/0x1e038A99AAC19162633Dcc4d215E5a27e6eB0355"
                                       target="_blank" rel="noreferrer"
                                       className="flex-row flex gap-2 items-center">
                                        <div
                                            className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                            aria-label="Smart Contract">
                                            <svg
                                                className="w-8 h-8 p-1 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="-38.39985 -104.22675 332.7987 625.3605">
                                                <path
                                                    d="M125.166 285.168l2.795 2.79 127.962-75.638L127.961 0l-2.795 9.5z"/>
                                                <path d="M127.962 287.959V0L0 212.32z"/>
                                                <path
                                                    d="M126.386 412.306l1.575 4.6L256 236.587l-128.038 75.6-1.575 1.92z"/>
                                                <path d="M0 236.585l127.962 180.32v-104.72z"/>
                                                <path d="M127.961 154.159v133.799l127.96-75.637z"/>
                                                <path d="M127.96 154.159L0 212.32l127.96 75.637z"/>
                                            </svg>
                                        </div>
                                        <a
                                            className="text-sm hover:underline">Smart Contract</a>
                                    </a>
                                </div>
                            </div>
                            <div className="w-full sm:w-1/2">
                                <div onClick={() => {
                                    if (window.innerWidth > 1000) {
                                        window.open("https://arcadetoads.mypinata.cloud/ipfs/bafybeihbijkydunsbxtg7wuvkordd352tfhxibek2xhcxldmfs5zmy7lfe", "_blank")
                                    }
                                }} style={{borderRadius: 15, overflow: "hidden"}}
                                     className="ml-0 sm:ml-10 sm:pt-0 rounded">
                                    <ReactPlayer width='100%'
                                                 height='100%' loop muted autoplay playing url={toadrunnerz}/>
                                </div>
                                <div onClick={() => {
                                    if (window.innerWidth > 1000) {
                                        window.open("https://arcadetoads.mypinata.cloud/ipfs/bafybeihbijkydunsbxtg7wuvkordd352tfhxibek2xhcxldmfs5zmy7lfe", "_blank")
                                    }
                                }} style={{borderRadius: 15, overflow: "hidden"}}
                                     className="mt-8 ml-0 sm:ml-10 sm:pt-0 rounded">
                                    <ReactPlayer width='100%'
                                                 height='100%' loop autoplay playing muted controls
                                                 url={toadrunnerz_promo}/>
                                </div>
                            </div>
                        </div>
                        <div style={{borderBottomLeftRadius: 15, borderBottomRightRadius : 15}}
                            className="shadow shadow-2xl bg-gray-200 dark:bg-arcade-200 p-8 flex-row flex items-center justify-center">
                            <ul className="flex items-center gap-6">
                                <li className="rounded-full mx-2">
                                    <a target="_blank" rel="noreferrer"
                                       href="https://opensea.io/collection/toadrunnerz"
                                       className="rounded-full px-4 py-2 text-gray-600 border-2 border-gray-600 mt-8">Sold
                                        Out</a>
                                </li>
                                <li className="rounded-full mx-2">
                                    <a target="_blank" rel="noreferrer"
                                       href="https://opensea.io/collection/toadrunnerz"
                                       className="rounded-full  dark:text-gray-900 px-4 py-2 dark:text-signature-900 bg-signature-900 border-gray-900 dark:hover:bg-signature-900 dark:hover:text-gray-900 border-2 dark:border-signature-900 mt-8">Browse</a>
                                </li>
                            </ul>
                        </div>

                    </div>

                </section>

                <section id="classics" className="relative pb-16">

                    <div className="max-w-6xl mx-auto px-5">

                        <div style={{borderTopLeftRadius: 15, borderTopRightRadius : 15}}
                            className="shadow shadow-2xl bg-gray-100 dark:bg-arcade-100 p-8 flex-col sm:flex-row flex items-start gap-6">
                            <div className="w-full sm:w-1/2 pb-4">
                                <p className="text-sm dark:text-gray-500">Drop #2</p>
                                <h2 className="uppercase text-2xl">Arcade Classics</h2>
                                <p className="dark:text-gray-300 mt-8">
                                    Arcade Classics is the second collection of unique Arcade consoles release by
                                    ArcadeNFT. Each with
                                    its exquisite design traits both externally and in-game.<br/><br/>

                                    Featuring 4 different game styles and millions of possible trait
                                    combinations<br/><br/>

                                    Drop Count: 5555<br/>
                                    Sold Out<br/>
                                </p>
                                <div className="mt-8 grid grid-cols-2 gap-8">
                                    <a href="classics/traits-table"
                                       className="flex-row flex gap-2 items-center">
                                        <div
                                            className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                            aria-label="Traits Table">
                                            <svg
                                                className="w-8 h-8 p-2 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-white"
                                                viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11 13H5C4.73478 13 4.48043 12.8946 4.29289 12.7071C4.10536 12.5196 4 12.2652 4 12V4C4 3.73478 4.10536 3.48043 4.29289 3.29289C4.48043 3.10536 4.73478 3 5 3H11C11.2652 3 11.5196 3.10536 11.7071 3.29289C11.8946 3.48043 12 3.73478 12 4V12C12 12.2652 11.8946 12.5196 11.7071 12.7071C11.5196 12.8946 11.2652 13 11 13ZM5 4V12H11V4H5Z"/>
                                                <path
                                                    d="M2 12H0V11H2V5H0V4H2C2.26522 4 2.51957 4.10536 2.70711 4.29289C2.89464 4.48043 3 4.73478 3 5V11C3 11.2652 2.89464 11.5196 2.70711 11.7071C2.51957 11.8946 2.26522 12 2 12Z"/>
                                                <path
                                                    d="M16 12H14C13.7348 12 13.4804 11.8946 13.2929 11.7071C13.1054 11.5196 13 11.2652 13 11V5C13 4.73478 13.1054 4.48043 13.2929 4.29289C13.4804 4.10536 13.7348 4 14 4H16V5H14V11H16V12Z"/>
                                            </svg>

                                        </div>
                                        <p className="text-sm hover:underline">Traits Table</p>
                                    </a>
                                    <a target="_blank" rel="noreferrer" href="https://rarity.tools/arcadenft"
                                       className="flex-row flex gap-2 items-center">
                                        <div
                                            className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                            aria-label="Rarity Tool">
                                            <svg
                                                className="w-8 h-8 p-2 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-white"
                                                viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.5 7C4.00555 7 3.5222 6.85338 3.11108 6.57867C2.69995 6.30397 2.37952 5.91352 2.1903 5.45671C2.00108 4.99989 1.95157 4.49723 2.04804 4.01228C2.1445 3.52732 2.3826 3.08187 2.73223 2.73223C3.08187 2.3826 3.52732 2.1445 4.01228 2.04804C4.49723 1.95157 4.99989 2.00108 5.45671 2.1903C5.91352 2.37952 6.30397 2.69995 6.57867 3.11108C6.85338 3.5222 7 4.00555 7 4.5C6.99927 5.16282 6.73565 5.79828 6.26696 6.26696C5.79828 6.73565 5.16282 6.99927 4.5 7ZM4.5 3C4.20333 3 3.91332 3.08797 3.66665 3.2528C3.41997 3.41762 3.22771 3.65189 3.11418 3.92598C3.00065 4.20007 2.97095 4.50167 3.02882 4.79264C3.0867 5.08361 3.22956 5.35088 3.43934 5.56066C3.64912 5.77044 3.91639 5.9133 4.20737 5.97118C4.49834 6.02906 4.79994 5.99935 5.07403 5.88582C5.34812 5.77229 5.58238 5.58003 5.74721 5.33336C5.91203 5.08668 6 4.79667 6 4.5C5.99956 4.10231 5.84139 3.72103 5.56018 3.43982C5.27897 3.15861 4.89769 3.00044 4.5 3Z"/>
                                                <path
                                                    d="M13.293 2.00001L2 13.293L2.70704 14L14 2.70705L13.293 2.00001Z"/>
                                                <path
                                                    d="M11.5 14C11.0055 14 10.5222 13.8534 10.1111 13.5787C9.69995 13.304 9.37952 12.9135 9.1903 12.4567C9.00108 11.9999 8.95157 11.4972 9.04804 11.0123C9.1445 10.5273 9.3826 10.0819 9.73223 9.73223C10.0819 9.3826 10.5273 9.1445 11.0123 9.04804C11.4972 8.95157 11.9999 9.00108 12.4567 9.1903C12.9135 9.37952 13.304 9.69995 13.5787 10.1111C13.8534 10.5222 14 11.0055 14 11.5C13.9993 12.1628 13.7356 12.7983 13.267 13.267C12.7983 13.7356 12.1628 13.9993 11.5 14ZM11.5 10C11.2033 10 10.9133 10.088 10.6666 10.2528C10.42 10.4176 10.2277 10.6519 10.1142 10.926C10.0007 11.2001 9.97095 11.5017 10.0288 11.7926C10.0867 12.0836 10.2296 12.3509 10.4393 12.5607C10.6491 12.7704 10.9164 12.9133 11.2074 12.9712C11.4983 13.0291 11.7999 12.9994 12.074 12.8858C12.3481 12.7723 12.5824 12.58 12.7472 12.3334C12.912 12.0867 13 11.7967 13 11.5C12.9996 11.1023 12.8414 10.721 12.5602 10.4398C12.279 10.1586 11.8977 10.0004 11.5 10Z"/>
                                            </svg>

                                        </div>
                                        <p className="text-sm hover:underline">Rarity Tool</p>
                                    </a>
                                    <a href="https://metadragonz.com/play" target="_blank" rel="noreferrer"
                                       className="flex-row flex gap-2 items-center">
                                        <div
                                            className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                            aria-label="Tournament">
                                            <svg
                                                className="w-8 h-8 p-2 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-white"
                                                viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M13 3.5H12V3C11.9997 2.73488 11.8942 2.4807 11.7068 2.29323C11.5193 2.10576 11.2651 2.0003 11 2H5C4.73488 2.0003 4.4807 2.10576 4.29323 2.29323C4.10576 2.4807 4.0003 2.73488 4 3V3.5H3C2.73488 3.5003 2.4807 3.60576 2.29323 3.79323C2.10576 3.9807 2.0003 4.23488 2 4.5V6C2.0006 6.53025 2.2115 7.03861 2.58644 7.41356C2.96139 7.7885 3.46975 7.9994 4 8H4.161C4.36934 8.76537 4.79603 9.45351 5.38897 9.9804C5.98191 10.5073 6.71545 10.8501 7.5 10.967V13H5V14H11V13H8.5V10.9656C9.29474 10.8673 10.0414 10.5318 10.6426 10.0027C11.2437 9.47373 11.6715 8.77579 11.87 8H12C12.5303 7.9994 13.0386 7.7885 13.4136 7.41356C13.7885 7.03861 13.9994 6.53025 14 6V4.5C13.9997 4.23488 13.8942 3.9807 13.7068 3.79323C13.5193 3.60576 13.2651 3.5003 13 3.5ZM4 7C3.73488 6.9997 3.4807 6.89424 3.29323 6.70677C3.10576 6.5193 3.0003 6.26512 3 6V4.5H4V7ZM11 7C11 7.40177 10.9193 7.79945 10.7627 8.16944C10.6061 8.53942 10.3767 8.87418 10.0883 9.15385C9.79981 9.43352 9.45812 9.6524 9.08347 9.7975C8.70881 9.9426 8.30883 10.011 7.90725 9.99855C7.11101 9.94917 6.36447 9.59467 5.82296 9.00883C5.28146 8.42299 4.98669 7.65091 5 6.85325V3H11V7ZM13 6C12.9997 6.26512 12.8942 6.5193 12.7068 6.70677C12.5193 6.89424 12.2651 6.9997 12 7V4.5H13V6Z"/>
                                            </svg>
                                        </div>
                                        <p
                                            className="text-sm hover:underline">Leaderboard</p>
                                    </a>
                                    <a href="https://etherscan.io/address/0xA0c38108bBB0F5f2fB46a2019D7314Cce38f3f22"
                                       target="_blank" rel="noreferrer" className="flex-row flex gap-2 items-center">
                                        <div
                                            className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                            aria-label="Smart Contract">
                                            <svg
                                                className="w-8 h-8 p-1 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="-38.39985 -104.22675 332.7987 625.3605">
                                                <path
                                                    d="M125.166 285.168l2.795 2.79 127.962-75.638L127.961 0l-2.795 9.5z"/>
                                                <path d="M127.962 287.959V0L0 212.32z"/>
                                                <path
                                                    d="M126.386 412.306l1.575 4.6L256 236.587l-128.038 75.6-1.575 1.92z"/>
                                                <path d="M0 236.585l127.962 180.32v-104.72z"/>
                                                <path d="M127.961 154.159v133.799l127.96-75.637z"/>
                                                <path d="M127.96 154.159L0 212.32l127.96 75.637z"/>
                                            </svg>
                                        </div>
                                        <p
                                            className="text-sm hover:underline">Smart Contract</p>
                                    </a>
                                </div>
                            </div>
                            <div className="w-full sm:w-1/2">
                                <div onClick={() => {
                                    if (window.innerWidth > 1000) {
                                        window.open("https://arcadenfts.mypinata.cloud/ipfs/bafybeidbhegr4e37wfzfbpqm6hbxvfdw5wnpc6hukz4yeqtn27apzazfzi", "_blank")
                                    }
                                }} style={{borderRadius: 15, overflow: "hidden"}}
                                     className="ml-0 sm:ml-10 sm:pt-0 rounded">
                                    <ReactPlayer width='100%'
                                                 height='100%' loop muted autoplay playing url={changa}/>
                                </div>
                                <div onClick={() => {
                                    if (window.innerWidth > 1000) {
                                        window.open("https://arcadenfts.mypinata.cloud/ipfs/bafybeidbhegr4e37wfzfbpqm6hbxvfdw5wnpc6hukz4yeqtn27apzazfzi", "_blank")
                                    }
                                }} style={{borderRadius: 15, overflow: "hidden"}}
                                     className="mt-8 ml-0 sm:ml-10 sm:pt-0 rounded">
                                    <ReactPlayer width='100%'
                                                 height='100%' muted playing autoplay loop controls
                                                 url={classics_promo}/>
                                </div>
                            </div>
                        </div>
                        <div style={{borderBottomLeftRadius: 15, borderBottomRightRadius : 15}}
                            className="shadow shadow-2xl bg-gray-200 dark:bg-arcade-200 p-8 flex-row flex items-center justify-center">
                            <ul className="flex items-center gap-6">
                                <li className="rounded-full mx-2">
                                    <a target="_blank" rel="noreferrer"
                                       href="https://opensea.io/collection/arcadenft?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Drop&search[stringTraits][0][values][0]=Arcade%20Classics"
                                       className="rounded-full px-4 py-2 text-gray-600 border-2 border-gray-600 mt-8">Sold
                                        Out</a>
                                </li>
                                <li className="rounded-full mx-2">
                                    <a target="_blank" rel="noreferrer"
                                       href="https://opensea.io/collection/arcadenft?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Drop&search[stringTraits][0][values][0]=Arcade%20Classics"
                                       className="rounded-full px-4 py-2 dark:text-signature-900 dark:text-gray-900 bg-signature-900 border-gray-900 dark:hover:bg-signature-900 dark:hover:text-gray-900 border-2 dark:border-signature-900 mt-8">Browse</a>
                                </li>
                            </ul>
                        </div>

                    </div>

                </section>

                <section id="genesis" className="relative pb-16">

                    <div className="max-w-6xl mx-auto px-5">

                        <div style={{borderTopLeftRadius: 15, borderTopRightRadius : 15}}
                            className="shadow shadow-2xl bg-gray-100 dark:bg-arcade-100 p-8 flex-col sm:flex-row flex items-start gap-6">
                            <div className="w-full sm:w-1/2 pb-4">
                                <p className="text-sm dark:text-gray-500">Drop #1</p>
                                <h2 className="uppercase text-2xl">Arcade Genesis</h2>
                                <p className="dark:text-gray-300 mt-8">
                                    We kicked off our launch with an exclusive collectable worth keeping for a
                                    while.<br/><br/>

                                    The righteous owners of our first NFT, endures highly exclusive & rare airdrops
                                    for life. Additionally, the Genesis Drop will enable several invite-only drops in
                                    the near future. Genesis owners are also eligible for voting & proposals in the
                                    Arcade DAO.
                                    <br/><br/>
                                    Drop Count: 500 Pieces<br/>
                                    Sold out
                                </p>
                                <div className="mt-8 grid grid-cols-2 gap-8">
                                    <a target="_blank" rel="noreferrer" href="https://snapshot.org/#/arcadenfts.eth"
                                       className="flex-row flex gap-2 items-center">
                                        <div
                                            className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                            aria-label="Dao Group">
                                            <svg
                                                className="w-8 h-8 p-2 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 196.338 196.338">
                                                <g>
                                                    <path d="M195.767,66.465c-0.761-1.838-2.221-3.298-4.059-4.059L63.483,9.295c-3.824-1.583-8.214,0.232-9.799,4.059L0.571,141.58
		c-0.762,1.838-0.762,3.902,0,5.74c0.761,1.838,2.221,3.298,4.059,4.059l86.104,35.665c0.919,0.38,1.895,0.571,2.87,0.571
		c0.976,0,1.951-0.19,2.87-0.571l59.566-24.676c1.838-0.761,3.298-2.221,4.059-4.059l35.667-86.105
		C196.528,70.368,196.528,68.303,195.767,66.465z M107.464,166.256l7.647-18.463l18.462,7.647L107.464,166.256z M149.112,145.639
		l-35.19-14.575c-3.823-1.583-8.214,0.232-9.799,4.059l-14.577,35.193l-72.248-29.925L64.672,26.023l114.367,47.371L149.112,145.639
		z"/>
                                                    <polygon
                                                        points="133.374,98.043 127.632,84.186 89.229,100.098 82.708,84.358 68.851,90.098 81.111,119.697 	"/>
                                                </g>
                                            </svg>


                                        </div>
                                        <p className="text-sm hover:underline">DAO Voting & Proposals</p>
                                    </a>
                                    <a href="https://etherscan.io/address/0xA0c38108bBB0F5f2fB46a2019D7314Cce38f3f22"
                                       target="_blank" rel="noreferrer" className="flex-row flex gap-2 items-center">
                                        <div
                                            className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                            aria-label="Smart Contract">
                                            <svg
                                                className="w-8 h-8 p-1 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="-38.39985 -104.22675 332.7987 625.3605">
                                                <path
                                                    d="M125.166 285.168l2.795 2.79 127.962-75.638L127.961 0l-2.795 9.5z"/>
                                                <path d="M127.962 287.959V0L0 212.32z"/>
                                                <path
                                                    d="M126.386 412.306l1.575 4.6L256 236.587l-128.038 75.6-1.575 1.92z"/>
                                                <path d="M0 236.585l127.962 180.32v-104.72z"/>
                                                <path d="M127.961 154.159v133.799l127.96-75.637z"/>
                                                <path d="M127.96 154.159L0 212.32l127.96 75.637z"/>
                                            </svg>
                                        </div>
                                        <a className="text-sm hover:underline">Smart Contract</a>
                                    </a>
                                </div>
                            </div>
                            <div className="w-full sm:w-1/2">
                                <div onClick={() => {
                                    if (window.innerWidth > 1000) {
                                        window.open("https://infura-ipfs.io/ipfs/QmWDsQX4UPvKw6J5PNNxzg3C8sVLF7imVr4qEh8Lp2sco5/index.html", "_blank")
                                    }
                                }} style={{borderRadius: 15, overflow: "hidden"}}
                                     className="ml-0 sm:ml-10 sm:pt-0 rounded">
                                    <ReactPlayer width='100%'
                                                 height='100%' loop muted autoplay playing url={cryptowave}/>
                                </div>
                                <div onClick={() => {
                                    if (window.innerWidth > 1000) {
                                        window.open("https://infura-ipfs.io/ipfs/QmWDsQX4UPvKw6J5PNNxzg3C8sVLF7imVr4qEh8Lp2sco5/index.html", "_blank")
                                    }
                                }} style={{borderRadius: 15, overflow: "hidden"}}
                                     className="mt-8 ml-0 sm:ml-10 sm:pt-0 rounded">
                                    <ReactPlayer width='100%'
                                                 height='100%' loop muted autoplay playing controls
                                                 url={genesis_promo}/>
                                </div>
                            </div>
                        </div>
                        <div style={{borderBottomLeftRadius: 15, borderBottomRightRadius : 15}}
                            className="shadow shadow-2xl bg-gray-200 dark:bg-arcade-200 p-8 flex-row flex items-center justify-center">
                            <ul className="flex items-center gap-6">
                                <li className="rounded-full mx-2">
                                    <a target="_blank" rel="noreferrer"
                                       href="https://opensea.io/collection/arcadenft?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Drop&search[stringTraits][0][values][0]=Genesis"
                                       className="rounded-full px-4 py-2 text-gray-600 border-2 border-gray-600 mt-8">Sold
                                        Out</a>
                                </li>
                                <li className="rounded-full mx-2">
                                    <a target="_blank" rel="noreferrer"
                                       href="https://opensea.io/collection/arcadenft?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Drop&search[stringTraits][0][values][0]=Genesis"
                                       className="rounded-full px-4 py-2 dark:text-signature-900  dark:text-gray-900 bg-signature-900 border-gray-900 dark:hover:bg-signature-900 dark:hover:text-gray-900 border-2 dark:border-signature-900 mt-8">Browse</a>
                                </li>
                            </ul>
                        </div>

                    </div>

                </section>

            </main>

            {/*  Site footer */}
            <Footer/>

        </div>
    );
}

export default Drops;
