import React, {useState, useEffect} from 'react';

import Header from '../partials/Header';
import Footer from '../partials/Footer';
import {useHistory} from 'react-router-dom'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const inside_traits = [
    {
        title: "Game Mode",
        image: "",
        traits: [
            {
                name: "Space",
                image: require("../assets/images/toadrunnerz/space.png").default,
                rarity: "11.70%",
                size: "80",
            },
            {
                name: "Aquatic",
                image: require("../assets/images/toadrunnerz/aquatic.png").default,
                rarity: "20.01%",
                size: "80",
            },
            {
                name: "Desert",
                image: require("../assets/images/toadrunnerz/desert.png").default,
                rarity: "30.62%",
                size: "80",
            },
            {
                name: "Racing",
                image: require("../assets/images/toadrunnerz/racing.png").default,
                rarity: "25%",
                size: "80",
            },
        ],
    },
];

const outside_traits = [
    {
        title: "Space",
        image: require("../assets/images/toadrunnerz/Space_Logo.png").default,
        traits: [
            {
                name: "Eagle",
                image: require("../assets/images/toadrunnerz/Eagle.png").default,
                rarity: "10.00%",
                size: "70",
            },
            {
                name: "Falcon",
                image: require("../assets/images/toadrunnerz/Falcon.png").default,
                rarity: "15.00%",
                size: "70",
            },
            {
                name: "Orbit",
                image: require("../assets/images/toadrunnerz/Orbit.png").default,
                rarity: "20.00%",
                size: "70",
            },
            {
                name: "None",
                image: "",
                rarity: "55.00%",
                size: "",
            },
        ],
    },
    {
        title: "Racing",
        image: require("../assets/images/toadrunnerz/Racing_Logo.png").default,
        traits: [
            {
                name: "Fuego",
                image: require("../assets/images/toadrunnerz/Fuego.png").default,
                rarity: "10.00%",
                size: "70",
            },
            {
                name: "Billboardz",
                image: require("../assets/images/toadrunnerz/Billboardz.png").default,
                rarity: "15.00%",
                size: "70",
            },
            {
                name: "Checkered Flags",
                image: require("../assets/images/toadrunnerz/CheckeredFlags.png").default,
                rarity: "20.00%",
                size: "70",
            },
            {
                name: "None",
                image: "",
                rarity: "55.00%",
                size: "",
            },
        ],
    },
    {
        title: "Desert",
        image: require("../assets/images/toadrunnerz/Desert_Logo.png").default,
        traits: [
            {
                name: "Lizard King",
                image: require("../assets/images/toadrunnerz/LizardKing.png").default,
                rarity: "10.00%",
                size: "70",
            },
            {
                name: "Eastwood",
                image: require("../assets/images/toadrunnerz/EastWood.png").default,
                rarity: "15.00%",
                size: "70",
            },
            {
                name: "Oasis",
                image: require("../assets/images/toadrunnerz/Oasis.png").default,
                rarity: "20.00%",
                size: "70",
            },
            {
                name: "None",
                image: "",
                rarity: "55.00%",
                size: "",
            },
        ],
    },
    {
        title: "Aquatic",
        image: require("../assets/images/toadrunnerz/Aquatic_Logo.png").default,
        traits: [
            {
                name: "Hostile Waters",
                image: require("../assets/images/toadrunnerz/HostileWaters.png").default,
                rarity: "10.00%",
                size: "70",
            },
            {
                name: "Meg",
                image: require("../assets/images/toadrunnerz/Meg.png").default,
                rarity: "15.00%",
                size: "70",
            },
            {
                name: "Lifesaver",
                image: require("../assets/images/toadrunnerz/LifeSaver.png").default,
                rarity: "20.00%",
                size: "70",
            },
            {
                name: "None",
                image: "",
                rarity: "55.00%",
                size: "",
            },
        ],
    },
    {
        title: "Background",
        image: "",
        traits: [
            {
                name: "Bermuda",
                image: require("../assets/images/toadrunnerz/Bermuda.png").default,
                rarity: "2.00%",
                size: "70",
            },
            {
                name: "Sunset",
                image: require("../assets/images/toadrunnerz/Sunset.png").default,
                rarity: "8.00%",
                size: "70",
            },
            {
                name: "Skyfall",
                image: require("../assets/images/toadrunnerz/Skyfall.png").default,
                rarity: "16.00%",
                size: "70",
            },
            {
                name: "None",
                image: "",
                rarity: "74.00%",
                size: "",
            },
        ],
    },
    {
        title: "Graffiti",
        image: "",
        traits: [
            {
                name: "Family",
                image: require("../assets/images/toadrunnerz/Family.png").default,
                rarity: "5.00%",
                size: "70",
            },
            {
                name: "Arcade",
                image: require("../assets/images/toadrunnerz/Arcade_Graffiti.png").default,
                rarity: "10.00%",
                size: "70",
            },
            {
                name: "Toadz",
                image: require("../assets/images/toadrunnerz/Toadz_Grafitti.png").default,
                rarity: "10.00%",
                size: "70",
            },
            {
                name: "None",
                image: "",
                rarity: "74.00%",
                size: "",
            },
        ],
    },
    {
        title: "Sticker Set",
        image: "",
        traits: [
            {
                name: "Shizzle",
                image: require("../assets/images/toadrunnerz/Shizzle.png").default,
                rarity: "1.00%",
                size: "70",
            },
            {
                name: "Rave",
                image: require("../assets/images/toadrunnerz/Rave.png").default,
                rarity: "5.00%",
                size: "70",
            },
            {
                name: "Boogie",
                image: require("../assets/images/toadrunnerz/Boogie.png").default,
                rarity: "20.00%",
                size: "70",
            },
            {
                name: "Disco",
                image: require("../assets/images/toadrunnerz/Disco.png").default,
                rarity: "25.00%",
                size: "70",
            },
            {
                name: "None",
                image: "",
                rarity: "49.00%",
                size: "",
            },
        ],
    },
];

function ToadrunnerzTraits(props) {

    const history = useHistory()

    const [tabs, setTabs] = useState(() => {
        return [
            {name: 'Outside Cadet', href: '#outside', current: true, data: outside_traits},
            {name: 'Inside Game', href: '#inside', current: false, data: inside_traits},
        ];
    });

    function getTraitsArray() {
        let toReturn = [];
        for (let i = 0; i < tabs.length; i++) {
            if (tabs[i].current) {
                toReturn = tabs[i].data;
            }
        }
        return toReturn;
    }

    useEffect(() => {
        return history.listen((location) => {
            const pathname = "#" + window.location.hash.substr(1);
            let newTabs = tabs.slice();
            for (let i = 0; i < newTabs.length; i++) {
                newTabs[i].current = newTabs[i].href === pathname;
            }
            setTabs(newTabs);
        })
    }, [history])

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header user={props.user}/>

            {/*  Page content */}
            <main className="grow">

                <section className="relative pt-32">

                    <div className="mx-auto max-w-6xl px-6 sm:px-8">
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                                Select a tab
                            </label>
                            <select
                                id="tabs"
                                name="tabs"
                                className="block w-full pl-3 pr-10 py-2 bg-transparent text-base border-gray-600 dark:border-gray-300 focus:outline-none focus:ring-signature-900 focus:border-signature-900 sm:text-sm rounded-md"
                                defaultValue={tabs.find((tab) => tab.current).name}
                            >
                                {tabs.map((tab) => (
                                    <option key={tab.name}>{tab.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="hidden sm:block">
                            <div className="">
                                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                    {tabs.map((tab) => (
                                        <a
                                            key={tab.name}
                                            href={tab.href}
                                            className={classNames(
                                                tab.current
                                                    ? 'dark:border-signature-900 border-gray-900 text-gray-900 dark:text-signature-900'
                                                    : 'border-transparent dark:text-gray-300 text-gray-500 dark:hover:text-gray-700',
                                                'whitespace-nowrap py-4 px-1 border-b-2 font-medium'
                                            )}
                                            aria-current={tab.current ? 'page' : undefined}
                                        >
                                            {tab.name}
                                        </a>
                                    ))}
                                </nav>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="relative pb-20">
                    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 pb-20">
                        <div
                            className="pt-6 md:pt-22 text-3xl text-center justify-center items-center flex flex-col"
                            data-aos="fade-down">
                            <img className="mb-6"
                                 src={require('../assets/images/toadrunnerz/Toadrunnerz_Logo.png').default}
                                 width="180" alt="Traits Glasses"/>
                            <h4 className="text-2xl text-center uppercase text-gray-900 dark:text-signature-900">Rarity
                                Traits</h4>
                        </div>
                    </div>
                    {getTraitsArray().map((data) => {
                            return (
                                <div key={data.title} className="max-w-6xl mx-auto pb-12">
                                    {!data.image && <h4 className="text-center uppercase pb-4">{data.title}</h4>}
                                    {data.image &&
                                    <div className="w-full flex justify-center items-center">
                                        <img className="mb-6 mt-5 flex" src={data.image}
                                             width="120" alt={data.title}/>
                                    </div>
                                    }
                                    <div className="flex flex-wrap justify-center" data-aos-id-team>
                                        {data.traits.map((trait) => {
                                            return (
                                                <div key={trait.name}
                                                     className={classNames(
                                                         data.href === "#outside"
                                                             ? 'w-1/2 sm:w-1/7 p-4 flex'
                                                             : 'w-1/2 sm:w-1/6 p-4 flex'
                                                     )}
                                                     className="w-1/2 sm:w-1/6 p-4 flex" data-aos="fade-down"
                                                     data-aos-anchor="[data-aos-id-team]">
                                                    <div style={{borderRadius: 16}}
                                                         className="flex flex-col bg-stone-200 dark:bg-arcade-100 flex-grow items-center justify-between py-12">
                                                        <div>
                                                            {trait.image &&
                                                            <img className="mb-6 mt-5 flex" src={trait.image}
                                                                 width={trait.size} alt={trait.name}/>}
                                                        </div>
                                                        <div className="flex text-center flex-col">
                                                            <p className="dark:text-gray-100 text-lg font-medium">{trait.name}</p>
                                                            <p className="dark:text-gray-300 text-sm font-medium">{trait.rarity}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            );
                        }
                    )}

                </section>


            </main>

            {/*  Site footer */}
            <Footer/>

        </div>
    );
}

export default ToadrunnerzTraits;
