import React from 'react';

import Header from '../partials/Header';
import Footer from '../partials/Footer';
import {Link} from "react-router-dom";

function Contact(props) {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header user={props.user}/>

            {/*  Page content */}
            <main className="grow">

                <section className="relative">

                    <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                        <div className="pt-32 pb-10 md:pt-40 text-3xl text-center" data-aos="fade-down">
                            <h2 className="text-center uppercase text-gray-900 dark:text-signature-900">Contact us</h2>
                        </div>
                    </div>
                    <div className="grid sm:grid-cols-2 flex max-w-6xl flex-row justify-center items-center mx-auto my-6 pb-12">
                        <div className="flex">
                            <div>
                                <p className="text-gray-600 dark:text-gray-300 mx-12">At Arcade NFTs, we have creativity as the core focus through all products we
                                    prototype &
                                    build. <br/><br/>You have an idea about a cool interactive NFT you want to create? –
                                    Lets talk!<br/><br/>
                                    Write us at <a href="mailto:hello@arcadenfts.com">hello@arcadenfts.com</a> or apply
                                    for a collaboration by clicking the
                                    button.</p>
                                <ul className="flex mt-16 mx-12">
                                    <li className="rounded-full">
                                        <a target="_blank" rel="noreferrer" href="https://81nyksuhvwu.typeform.com/to/nbJk6vWn"
                                              className="bg-arcade-button rounded-full border-2 border-gray-900 dark:border-signature-900 px-4 py-2 text-gray-600 dark:text-white dark:hover:text-gray-900 hover:bg-signature-900 mt-8">Apply
                                            4 Collab</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-full sm:mx-12 mt-14 sm:mt-0">
                            <img className="mb-6"
                                 src={require('../assets/images/arcade_collage.png').default} width="100%"
                                 alt="Collage"/>
                        </div>
                    </div>
                </section>

            </main>

            {/*  Site footer */}
            <Footer/>

        </div>
    );
}

export default Contact;
