import React from 'react';
import Header from '../partials/Header';
import Footer from '../partials/Footer';

function Terms(props) {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header user={props.user}/>

            {/*  Page content */}
            <main className="grow">

                <section className="relative">
                    <div className="relative max-w-6xl mx-auto px-4 sm:px-6 pb-12">
                        <div className="pt-32 pb-10 md:pt-40 text-3xl text-center" data-aos="fade-down">
                            <h2 className="text-center uppercase text-gray-900 dark:text-signature-900">Terms &
                                Conditions</h2>
                        </div>
                    </div>
                    <div className="max-w-6xl mx-auto pb-20">
                        <p className="dark:text-gray-300 text-sm pb-20">
                            Integrity Policy for Protection of Personal Data<br/><br/>
                            We respect your privacy. In this integrity policy for the protection of personal data
                            (hereinafter referred to as the ”Policy”), we describe how we process personal data, which
                            personal data we collect, why we collect it, with whom we share the personal data, how we
                            protect it and which choices you can make with respect to our processing of your personal
                            data. The Policy is applicable on all personal data collected, stored or processed by or on
                            behalf of the team behind https://arcadenfts.com/ (the “Team”, “we” or “us”) which has a
                            connection to a specific individual in his/her relations with the Team as a customer,
                            consumer or which is available in the public domain. The Team is responsible for the
                            processing of personal data.<br/><br/>
                            The Policy also includes all websites or mobile websites owned by the Team where personal
                            data is processed, such as https://arcadenfts.com/ (such website, together with any other
                            website owned by the Team, is hereinafter collectively referred to as the “Website”). The
                            customer (hereinafter referred to as “you”) consents through using the Website and/or
                            conveying personal data (or other information) on the Website to that we collect and process
                            the customer’s personal data as described in this Policy.<br/><br/>
                            The requirements and guidelines stipulated in this Policy is a supplement to applicable laws
                            and regulations on protection of personal data and does not intend to replace any such
                            applicable laws or regulations on protection of personal data. In the event of a conflict
                            between applicable laws and regulations on protection of personal data and the requirements
                            and guidelines in this Policy, the applicable laws or regulations on protection of personal
                            data shall prevail. The Team can amend this Policy at any time. It is recommended that you
                            read this Policy from time to time via https://arcadenfts.com/ in order to be updated in
                            relation to any amendments to this Policy.<br/><br/>

                            1. DEFINITIONS<br/>
                            1.1 In this Policy, a number of definitions are used. They have the following meanings:<br/>
                            (a) Processing: mean such an action or series of actions that are taken in relation to
                            Personal Data, such as collection, documentation, organization, storage, adjustment or
                            modification, compilation, consultation, usage, disclosure through transfer, deconstruction,
                            combination, blockage, deletion or destruction.<br/>
                            (b) EEA: means the European Economic Area (consisting of the countries being members in the
                            EU, plus Iceland, Norway and Liechtenstein).<br/>
                            (c) Personal Data: means data that can be referred to a living person and which makes it
                            possible to identify such person, alone or together with a combination of other information.
                            A description of which Personal Data that is processed by the Team can be found in Section 3
                            below.<br/><br/>
                            2. MAIN PRINCIPLES IN THE POLICY<br/>
                            We appreciate to be given the confidence to process Personal Data and we are determined to
                            process them in a correct, transparent and secure manner. We have, inter alia, the following
                            main principles in our Policy:<br/><br/>
                            Collection of information: We only collect Personal Data in a correct, legal and
                            transparent way.<br/><br/>
                            Data minimization: We limit the collection of Personal Data to what we deem to be relevant
                            and important based on the purposes described in this Policy.<br/><br/>
                            Correct information: We make sure that your Personal Data is kept updated and
                            correct.<br/><br/>
                            Data security: We take appropriate technical and organizational measures in order to
                            create an adequate level of security in relation to the risks inherent in the handling of
                            the Personal Data to be protected. Such measures shall prevent unlawful disclosure of, or
                            unlawful access to, Personal Data and also prevent accidents, losses, amendments or other
                            unlawful processing.<br/><br/>
                            Availability and rectifications: We will process your Personal Data with your right to
                            personal integrity taken into account.<br/><br/>
                            Storage of information: We will store your Personal Data in a way which is in accordance
                            with applicable laws and regulations on protection of personal data. We will not store your
                            Personal Data during a longer period of time than what can be deemed necessary based on the
                            purposes described in this Policy.<br/><br/>
                            Third parties: We will ensure that access to, and transfer of, Personal Data by and to
                            third parties are carried out in accordance with applicable laws and regulations on
                            protection of personal data and that appropriate agreed security
                            measures are taken in relation to such transfer.<br/><br/>
                            Direct marketing and cookies: When we send you advertisements or place cookies on your
                            computer, we will ensure that is made in accordance with applicable laws and
                            regulations.<br/><br/>

                            3. COLLECTION OF DATA<br/>
                            3.1 PERSONAL DATA THAT YOU GIVE DIRECTLY TO US<br/>
                            We may collect information directly from you when you leave information on the Website, in
                            the Team’s Discord channel that you can join through the following link: (the “Discord
                            Channel”), or when you call or e-mail us or leave
                            information directly to us in any other way. The Personal Data that you leave directly to us
                            can include:<br/><br/>
                            (a) name, age, phone number, email-address, nationality, wallet address, holdings of digital
                            currencies, etc.<br/><br/>
                            3.2 PERSONAL DATA WE COLLECT AUTOMATICALLY<br/>
                            When you visit the Website or in any other way communicate with the Team online, the
                            following information can be collected (pursuant to Section 10 below):<br/><br/>
                            (a) Electronic ID-information, IP-addresses, cookies and log-files, connection times,
                            technical user information, technical and historical information from your browser such as
                            language settings, previously visited websites and operative system.<br/><br/>

                            4. WHY DOES THE TEAM NEED YOUR PERSONAL DATA?<br/>
                            4.1 The Team processes your Personal Data for the following purposes (hereinafter referred
                            to collectively as the ”Purposes” and each individually as a “Purpose”):<br/><br/>
                            (a) Request for information: To answer your questions or effectuate your requests.<br/>
                            (b) Newsletters: To send newsletters to you (if you have chosen to subscribe to the Team’s
                            newsletters at any of the Team’s websites);<br/>
                            (c) Direct marketing: In order to send e-mails to you or contact you through any other
                            channel of communication you have given us, about content we think that you might be
                            interested in, including information from selected business partners and their respective
                            products and/or services, all of which shall be carried out in
                            accordance with Section 13 of this Policy;<br/>
                            (d) Advertisements, marketing and PR: For advertisements and marketing of the Team’s
                            business, studies of customer preferences and for PR-activities in relation to the Team’s
                            business;<br/>
                            (e) Security: This Purpose entails all methods, systems and processes the Team uses to
                            protect its intellectual property rights, economic and financial interests and to protect
                            the integrity of its employees and customers;
                            (f) IT-support and development;<br/>
                            (g) Compliance with laws and legal requirement: This Purpose includes being able to
                            guarantee compliance with laws and regulations and to take initiatives to, progress/advance
                            or defend against legal claims;<br/>
                            (h) Historic and statistical research: This Purpose includes, inter alia, collection and
                            processing of Personal Data for statistical research (or where it is important to produce
                            statistical results), analysis of earlier events and in order to establish behavioural
                            patterns and similar things;<br/>
                            (i) To analyse your usage of the Website in order to improve and develop the Website and/or
                            new services and products, and to inform about updates to the Website;<br/>
                            (j) To prepare and carry out a consolidation, joint venture, acquisition, divestment or
                            transfer of Personal Data, assets, all or part of the stock in the Team or the Team’s
                            business, or any other ownership change, reorganization or corporate
                            transaction (including financing of the Team or financing of any part of the corporate
                            events mentioned in this sub-paragraph); and<br/>
                            (k) Any other purpose that has been described and communicated to you before your Personal
                            Data is used for such other purpose.<br/><br/>

                            4.2 The Team may only process Personal Data to the extent necessary to achieve the relevant
                            Purpose and for other purposes that are explicitly permitted pursuant to applicable laws or
                            regulations on protection of Personal Data.<br/>
                            4.3 To the extent necessary pursuant to applicable laws or regulations on protection of
                            personal data, the Team shall notify relevant authorities on its processing of Personal
                            Data.<br/><br/>

                            DATA CONTROLLER<br/>
                            The Team is the data controller for all processing of Personal Data that the Team, or any
                            other party on behalf of the Team, conducts if not otherwise stipulated in this Policy.
                            <br/><br/>
                            LEGAL BASIS<br/>
                            The legal basis for Processing Personal Data is consent from the customer, by way of the
                            customer accessing the Website, and/or approving applicable general terms and conditions (as
                            relevant), and/or utilizing the services provided at the Website.<br/><br/>
                            CORRECTNESS OF PERSONAL DATA<br/>
                            It is important to us that your Personal Data is updated and correct. Please inform us of
                            any changes to, or inaccuracies in, your Personal Data as soon as possible. We will do our
                            best to make sure that inaccurate or obsolete Personal Data is deleted, destroyed or
                            corrected. If you believe that the Personal Data we have about you is inaccurate or
                            obsolete, you are entitled to request that our processing of Personal Data is limited while
                            we control if the relevant Personal Data is correct or incorrect.<br/><br/>
                            METICULOUS HANDLING AND STORAGE<br/>
                            We will store your Personal Data in a way that is pursuant to applicable laws or regulations
                            on protection of personal data. We will store the Personal Data as long as is necessary
                            pursuant to applicable laws and regulation or pursuant to the Purposes.<br/><br/>
                            DATA SECURITY<br/>
                            9.1 We ensure that technical and organizational measures are taken in order to prevent
                            unlawful or disallowed processing of Personal Data and also other incorrect usage,
                            destruction, disclosure, acquisition of and loss of Personal Data in the event of an
                            accident. Personal Data may only be processed by a third party that is a personal data
                            processor if such personal data processor undertakes to comply with the aforementioned
                            technical and organizational security measures.<br/>
                            9.2 Maintenance of data security entails guaranteeing non-disclosure, integrity and access
                            to the Personal Data:<br/><br/>
                            (a) Non-disclosure: We protect your Personal Data so that it is not unlawfully disclosed to
                            a third party.<br/>
                            (b) Integrity: We protect your Personal Data so that it is not unlawfully amended by an
                            unauthorized third party.<br/>
                            (c) Access: We ensure that authorized third parties, if necessary and lawful, will be given
                            access to your Personal Data.<br/>
                            9.3 In the security measures, the following elements are included:
                            (a) Risk analysis and risk assessment:<br/>
                            (b) Organization and human aspects of security: classing of information, information and
                            education of employees, disciplinary actions upon violations of the rules, employees’
                            awareness of the importance of non-disclosure, effects on
                            outsourcing agreements;<br/>

                            (c) Physical security and environmental security: ensure physical access, prevent and
                            discover/handle physical hazards (fire, water, etc.), backup-systems;<br/>
                            (d) Network security: availability security, list of involved employees, authentication
                            systems;<br/>
                            (e) Access logs, tracking and analysis;<br/>
                            (f) Supervisory actions, judgments and maintenance;<br/>
                            (g) Handling of security incidents and continuity: surveillance systems for security
                            contingencies, preparation of incident/catastrophy plan, continuity plan; and<br/>
                            (h) Complete and updated documentation.
                            <br/><br/>
                            COOKIES<br/>
                            Usage of Cookies<br/>
                            10.1 We use information from cookies in order to make the Website more user friendly. We can
                            also use certain authorized third parties to, in our place, put cookies on the Website in
                            order to deliver services from them.<br/>
                            10.2 We use permanent cookies in several ways, including, but not limited to:<br/><br/>
                            (a) Enabling you to move between places at our website and bring information to such places,
                            without having to enter such information more than once;<br/>
                            (b) Making it easier for us to adjust the Website so that you recognize the website from one
                            of your previous visits;<br/>
                            (c) Giving you access to stored information; and<br/>
                            (d) Making certain that we will not ask you to fill out the same questionnaires multiple
                            times.<br/><br/>
                            10.3 We (and our authorized third parties) may come to use non-personal information from
                            both permanent cookies and temporary cookies for statistical purposes, such as:<br/><br/>
                            (a) Evaluating the Website’s contents and functions;<br/>
                            (b) Monitoring the usage of the Website (frequency and time);<br/>
                            (c) To perform surveys so that your interaction with the Website and with us can be
                            improved.<br/><br/>

                            List of cookies<br/>
                            10.4 We use the following cookies in the following manner:<br/><br/>
                            (a) [Cookies.accepted (permanent) – is used in order to confirm that you have received
                            information on our usage of cookies]<br/><br/>
                            Third party cookies<br/>
                            10.5 We use a number of third party cookies as a part of our service. These cookies are
                            regulated by the respective websites from which they derive and we do not control them. We
                            have listed the third party cookies we use below. Certain of these cookies can be turned off
                            in your browser settings while others can only be turned off at the
                            respective third parties’ respective websites, in accordance with the instructions given at
                            such websites. Our third parties can also use additional cookies over which we have no
                            control.<br/><br/>
                            (a) Google Analytics – is used as an analysis tool. For further information about this third
                            party cookie, please visit: https://www.google.se/analytics. In order to turn off such third
                            party cookie, please see: https://tools.google.com/dlpage/gaoptout<br/>
                            (b) Google Optimize – is used to create different versions of the website in order to see
                            which website performs most according to our preferences. For more information, please see:
                            https://www.google.com/analytics/optimize/<br/><br/>
                            Deletion of cookies<br/>
                            10.6 You can choose yourself whether you want to accept receiving cookies or not. If you
                            want to know when your computer receives a cookies, you can set your browser settings
                            so that you are notified thereof. In such way, you will have the opportunity to choose
                            whether you shall accept or reject a cookie. Your computer can also be set to reject all
                            cookies. Visit https://aboutcookies.org if you want to know how to make this setting.
                            Please be aware that changes in your browser meaning that the cookie-function is
                            disabled will prevent certain parts of our website from functioning correctly.
                            <br/><br/>
                            SHARING PERSONAL DATA<br/>
                            11.1 We may share your Personal Data for the purposes listed above with the following
                            categories of recipients:<br/><br/>
                            (a) Authorized employees/partners in the group of companies to which the
                            Team belongs;<br/>
                            (b) Team partners and subsidiaries to or in the group of companies to which the
                            Team belongs (which includes all entities listed at our website);<br/>
                            (c) Our advertising and marketing agents: in order to help us deliver and analyze
                            the effects of our marketing campaigns and offers;<br/>
                            (d) Service providers: companies who provide services for certain purposes on
                            behalf of the Team. The Team can, for instance, share your Personal Data with
                            external IT-service providers;<br/>
                            (e) Other parties when it is required by law or in order to protect the Team: The
                            Team may share your Personal Data with third parties in order to (i) comply with
                            laws, decisions from supervisory authorities, court orders, or if necessary or
                            advisable in legal proceedings, (ii) verify and supervise the Team’s compliance
                            with the Team’s policies and agreements, and (iii) protect the Team’s and/or the
                            Team’s customer’s rights, property and security;<br/>
                            (f) Other parties in connection with Team transactions and financings: The
                            Team may share your Personal Data with third parties in connection with a
                            consolidation, joint venture, acquisition, divestment or transfer of Personal Data,
                            assets, all or part of the stock in the Team or the Team’s business, or any other
                            ownership change, reorganization or corporate transaction (including financing of
                            the Team or financing of any part of the corporate events mentioned in this sub-
                            paragraph); and<br/>
                            (g) Other parties after your consent thereto or after your instruction thereof:
                            The Team may share your Personal Data with other third parties after you consent
                            to such sharing or give instructions of such sharing.<br/><br/>

                            11.2 Please note that the recipients mentioned above, in particular service providers
                            offering products and/or services to you through any Team-program or Team-website, may
                            collect information about you separately and that they are responsible for the
                            processing, control and storage of such information. Your rights in relation to such
                            service provider are governed by such service provider’s terms and conditions, and you
                            should contact such service provider if you have any questions in relation to the
                            processing, control and storage of such information.<br/><br/>

                            DISCLOSURE OUTSIDE EU/EEA<br/>
                            Your Personal Data may be transferred to a third party as stipulated in this Policy. Such
                            third parties may be domiciled outside of EU/EEA and the information can be
                            processed by us and any such third party anywhere in the world. The countries to which
                            your Personal Data may be transferred may possibly not offer a sufficient protection of
                            personal data. In connection with transferring Personal Data to countries who do not
                            have the same data protection levels as the EU/EEA, the Team shall strive to ensure that
                            actions are taken in order to secure that your Personal Data is adequately
                            protected.<br/><br/>
                            YOUR RIGHTS<br/>
                            13.1 Your Personal Data: You can always contact us via mail or email and control which
                            information we have of you, from where it originates and how we have used it. You are
                            also entitled to receive access to the Personal Data we have collected on you.<br/>
                            13.2 Your corrections and your access: If you believe that there are inaccuracies in your
                            Personal Data, or that they are incomplete, you may also request that we change or
                            supplement such incomplete or incorrect Personal Data.<br/>
                            13.3 Your objections: You may object to the processing of your Personal Data for direct
                            marketing purposes (if you wish, you may also clarify for us how often and through
                            which channel you would like to be contacted by us) or to sharing of your Personal Data
                            with third parties for the same purpose (see Section 4.1(c) above).<br/>
                            13.4 Data portability: You may ask to receive your Personal Data in a structured, electronic
                            and commonly used format. You are also entitled to transfer your Personal Data to
                            another Personal Data controller without us obstructing that, as long as it is technically
                            feasible. If you would like to exercise any of your rights pursuant to this Section 13.4,
                            please contact the Team through hello@arcadenfts.com.<br/>
                            13.5 You can also require that we delete information about you, as long as it is technically
                            feasible (however, excluding information that are verifications of a transaction or
                            information we are required to maintain pursuant to any law or regulation).<br/>
                            13.6 Contacts pursuant to Sections 13.1 – 13.5 shall be directed to the Team at
                            hello@arcadenfts.com. Objections pursuant to Section 13.3 may also be presented through
                            using the
                            unsubscription preference settings in the marketing material.<br/><br/>
                            AMENDMENTS<br/>
                            14.1 The Team is entitled to amend this Policy by posting an updated Policy including
                            relevant amendments at the Website. The amendments will come into force with
                            immediate effect.<br/><br/>
                        </p>
                    </div>
                </section>

            </main>

            {/*  Site footer */}
            <Footer/>

        </div>
    );
}

export default Terms;
