export const FIR_CONFIG = {
    apiKey: "AIzaSyBcSse7Vn4jkfwyOnelrpp8Xz4Gdm5py70",
    authDomain: "arcade-tournament.firebaseapp.com",
    databaseURL: "https://arcade-tournament-default-rtdb.firebaseio.com",
    projectId: "arcade-tournament",
    storageBucket: "arcade-tournament.appspot.com",
    messagingSenderId: "446942316506",
    appId: "1:446942316506:web:9e3418660a3f684cbae2e3",
    measurementId: "G-EN0KLD9XT4"
};
