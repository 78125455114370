import React, {useEffect, useState} from 'react';
import {
    Switch,
    Route,
    useLocation
} from 'react-router-dom';
import './css/style.scss';
import AOS from 'aos';
import WalletConnectProvider from "@walletconnect/web3-provider";
import {
    INFURA_ID,
    TOADRUNNERZ_CONTRACT_ADDRESS,
    GENESIS_CONTRACT_ADDRESS,
    TOADRUNNERZ_ABI,
    GENESIS_ABI,
    MAINTENANCE,
    METADRAGONZ_ABI,
    METADRAGONZ_CONTRACT_ADDRESS
} from './constants/vars';
import {
    FIR_CONFIG
} from './constants/config';
import firebase from 'firebase/compat/app';
import {getAnalytics} from "firebase/analytics";
import 'firebase/compat/firestore';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WalletLink from 'walletlink';
import axios from 'axios';

import Home from './pages/Home';
import MyArcades from './pages/MyArcades';
import Drops from './pages/Drops';
import About from './pages/About';
import Contact from './pages/Contact';
import Help from './pages/Help';
import Connect from './pages/Connect';
import Terms from './pages/Terms';
import ClassicsTraits from './pages/ClassicsTraits';
import ToadrunnerzTraits from './pages/ToadrunnerzTraits';
import ClassicsRarity from './pages/ClassicsRarity';
import ToadrunnerzRarity from './pages/ClassicsRarity';
import PageNotFound from './pages/PageNotFound';

const firApp = firebase.initializeApp(FIR_CONFIG);
const analytics = getAnalytics(firApp);
const Web3 = require("web3");
const walletConnectProvider = new WalletConnectProvider({
    infuraId: INFURA_ID,
});
const walletLink = new WalletLink({
    appName: "Arcade NFT",
    appLogoUrl: require("../src/assets/images/arcade-logo.png"),
    darkMode: localStorage.getItem('dark-mode') === null || localStorage.getItem('dark-mode') === 'true',
});

function App() {

    const location = useLocation();

    const metamaskLogin = async () => {
        if (isMetaMaskEnabled()) {
            await initUserWallet("metamask");
        } else {
            window.open("https://metamask.io/download", "_blank")
        }
    }

    const walletLinkLogin = async () => {
        window.ethereum = walletLink.makeWeb3Provider("https://mainnet.infura.io/v3/78b30def5a924d86829f2d2678619aa9", 1)
        await initUserWallet("walletlink")
    }

    const walletConnectLogin = async () => {
        try {
            await walletConnectProvider.enable();
        } catch (e) {
            window.location.reload();
            console.log(e);
        }
    }

    const [user, setUser] = useState(() => {
        return {
            connected: false,
            walletAddress: "",
            chainId: null,
            balance: 0,
            walletType: null,
            arcadeClassicsTokens: [],
            toadRunnerzTokens: [],
            genesisTokens: [],
            metadragonzTokens : [],
            toadRunnerzContract: null,
            genesisContract: null,
            fireRef: null,
            username: "",
            formattedWalletAddress: "",
            hasLoadedTokens: false,
            hasLoadedTokensPercentage: 0,
            randomToken: null,
            signInWithMetamask: metamaskLogin,
            signInWithWalletConnect: walletConnectLogin,
            signInWithWalletLink: walletLinkLogin,
        }
    });

    const initUserWallet = async (walletType) => {
        window.web3 = walletType === "walletconnect" ? new Web3(walletConnectProvider) : new Web3(window.ethereum);
        try {
            const accounts = walletType === "walletconnect" ? await window.web3.eth.getAccounts() : await window.ethereum.request({method: 'eth_requestAccounts'});
            if (accounts.length > 0) {
                const wei = await window.web3.eth.getBalance(accounts[0]);
                const balance = window.web3.utils.fromWei(wei);
                const chainId = await window.web3.eth.getChainId();
                const genesisContract = new window.web3.eth.Contract(GENESIS_ABI, GENESIS_CONTRACT_ADDRESS);
                const toadRunnerzContract = new window.web3.eth.Contract(TOADRUNNERZ_ABI, TOADRUNNERZ_CONTRACT_ADDRESS);
                const metadragonzContract = new window.web3.eth.Contract(METADRAGONZ_ABI, METADRAGONZ_CONTRACT_ADDRESS);
                setUser(prevUser => {
                    return {
                        ...prevUser,
                        connected: true,
                        walletAddress: accounts[0],
                        balance: balance,
                        chainId: chainId,
                        genesisContract: genesisContract,
                        toadRunnerzContract: toadRunnerzContract,
                        metadragonzContract: metadragonzContract,
                        walletType: walletType,
                        formattedWalletAddress: accounts[0].substring(0, 6) + "..." + accounts[0].substring(34),
                        walletDisconnect: walletDisconnect,
                    }
                });
                if (chainId !== 1) {
                    notify("Wrong Network. Switch to Ethereum Mainnet", false, toast.TYPE.ERROR)
                } else {
                    notify('Wallet Connected', 3000, toast.TYPE.SUCCESS);
                    //window.location.pathname = "/my-arcades";
                }
            }
        } catch (e) {
            if (e && e.code && e.code === -32002) {
                notify("Your wallet already has a pending request to sign in", true, toast.TYPE.DEFAULT)
            }
        }
    }

    function getUserInfo() {
        firebase.firestore().collection("Users").doc(user.walletAddress).get().then(user => {
            if (user.exists) {
                setUser(prevUser => {
                    return {
                        ...prevUser,
                        username: user.data().username,
                        fireRef: user.ref,
                    }
                });
            }
        });
    }

    async function getTokens() {
        if (user.chainId === 1) {
            const genesisBalance = Number(await user.genesisContract.methods.balanceOf(user.walletAddress).call());
            const toadrunnerzBalance = Number(await user.toadRunnerzContract.methods.balanceOf(user.walletAddress).call());
            const metadragonzBalance = Number(await user.metadragonzContract.methods.balanceOf(user.walletAddress).call());
            let genesisTokens = [];
            let arcadeClassicsTokens = [];
            let toadRunnerzTokens = [];
            let metadragonzTokens = [];
            let index = 0;
            let loaded = 0;
            while (index < genesisBalance) {
                const tokenId = await user.genesisContract.methods.tokenOfOwner(user.walletAddress, index).call();
                if (tokenId !== 4556 && tokenId > 4 && tokenId !== 19) {
                    if (tokenId <= 500) {
                        const token = await firebase.firestore().collection("ArcadeGenesis").doc(tokenId.toString()).get();
                        if (token.exists) {
                            genesisTokens.push({...token.data(), tokenId});
                        }
                    } else if (tokenId <= 6065) {
                        const token = await firebase.firestore().collection("ArcadeClassics").doc(tokenId.toString()).get();
                        if (token.exists) {
                            arcadeClassicsTokens.push({...token.data(), tokenId});
                        }
                    }
                }
                loaded++;
                index++;
                setUser(prevUser => {
                    return {
                        ...prevUser,
                        hasLoadedTokensPercentage: Math.round(loaded / (genesisBalance + toadrunnerzBalance + metadragonzBalance) * 100),
                    }
                });
            }
            index = 0;
            while (index < toadrunnerzBalance) {
                const tokenId = await user.toadRunnerzContract.methods.tokenOfOwner(user.walletAddress, index).call();
                console.log(tokenId)
                const token = await firebase.firestore().collection("ToadRunnerz").doc(tokenId.toString()).get();
                if (token.exists) {
                    toadRunnerzTokens.push({...token.data(), tokenId});
                }
                loaded++;
                index++;
                setUser(prevUser => {
                    return {
                        ...prevUser,
                        hasLoadedTokensPercentage: Math.round(loaded / (genesisBalance + toadrunnerzBalance + metadragonzBalance) * 100),
                    }
                });
            }
            index = 0;
            while (index < metadragonzBalance) {
                const tokenId = await user.metadragonzContract.methods.tokenOfOwnerByIndex(user.walletAddress, index).call();
                const tokenUri = await user.metadragonzContract.methods.tokenURI(tokenId).call();
                const metadata = await axios.get(tokenUri);
                if (metadata && metadata.data) {
                    metadragonzTokens.push({...metadata.data, tokenId});
                }
                loaded++;
                index++;
                setUser(prevUser => {
                    return {
                        ...prevUser,
                        hasLoadedTokensPercentage: Math.round(loaded / (genesisBalance + toadrunnerzBalance + metadragonzBalance) * 100),
                    }
                });
            }
            setUser(prevUser => {
                return {
                    ...prevUser,
                    toadRunnerzTokens,
                    genesisTokens,
                    arcadeClassicsTokens,
                    metadragonzTokens,
                    hasLoadedTokens: true,
                }
            });
        }

        /*
                const res2 = await axios.get("https://www.niftyriver.io/api/platforms/collection/0xA0c38108bBB0F5f2fB46a2019D7314Cce38f3f22/rarity/?page_size=500&page=4");

                let i = 0;
                if (res2.status === 200) {
                    while (i < Number(res2.data.results.length)) {
                        let doc = await firebase.firestore().collection("ArcadeClassics").doc(res2.data.results[i].token_id).get();
                        if(res2.data.results[i].name === "Pinball"){
                            doc = await firebase.firestore().collection("ArcadeGenesis").doc(res2.data.results[i].token_id).get();
                        }
                        if(res2.data.results[i].name === "Arcade Award"){
                            doc = await firebase.firestore().collection("ArcadeAward").doc(res2.data.results[i].token_id).get();
                        }
                        const tokenUri = await user.genesisContract.methods.tokenURI(Number(res2.data.results[i].token_id)).call();
                        const data = await axios.get(tokenUri);
                        await doc.ref.set({...res2.data.results[i], ...data.data, tokenId : res2.data.results[i].token_id});
                        console.log(i);
                        i++;
                    }
                }
                */
    }

    function getRandomCollection() {
        const random = Math.floor(Math.random() * 3);
        const toReturn = random === 1 ? "ToadRunnerz" : random === 2 ? "ArcadeClassics" : "ArcadeGenesis";
        return toReturn;
    }

    function getRandomIntInCollection(collection) {
        const max = collection === "ArcadeGenesis" ? 500 : 3000;
        const min = collection === "ArcadeClassics" ? 500 : 1;
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    function getRandomToken() {
        const collection = getRandomCollection();
        firebase.firestore().collection(collection).doc(getRandomIntInCollection(collection).toString()).get().then(token => {
            if (token.exists && !(token.data().name === "Pinball" && window.innerWidth < 1000)) {
                setUser(prevUser => {
                    return {
                        ...prevUser,
                        randomToken: token.data(),
                    }
                });
            } else {
                getRandomToken();
            }
        });
    }

    async function initListeners() {
        if (isEthereumEnabled()) {
            window.ethereum.on('accountsChanged', (accounts) => {
                if (accounts.length === 0) {
                    window.location.reload();
                } else if (accounts[0] !== user.walletAddress && user.walletAddress !== "") {
                    if (isMetaMaskEnabled()) {
                        initUserWallet("metamask");
                    } else if (isEthereumEnabled()) {
                        initUserWallet('walletlink');
                    }
                }
            });
            window.ethereum.on('chainChanged', (chainId) => {
                window.location.reload();
            });
            window.ethereum.on('disconnect', (providerRpcError) => {
                window.location.reload();
            });
        }
        walletConnectProvider.on("accountsChanged", async (accounts) => {
            await initUserWallet("walletconnect");
        });
        walletConnectProvider.on("chainChanged", (chainId) => {
            window.location.reload();
        });
        walletConnectProvider.on("disconnect", async (code, reason) => {
            await walletDisconnect();
        });
        walletConnectProvider.on("connect", async () => {
            await initUserWallet("walletconnect");
        });
    }

    const walletDisconnect = async (user) => {
        window.scrollTo(0, 0);
        if (user.walletType === "walletconnect") {
            try {
                await walletConnectProvider.disconnect();
                window.location.reload();
            } catch (e) {
                console.log(e);
            }
        }
        if (user.walletType === "walletlink") {
            try {
                await walletLink.disconnect();
                window.location.reload();
            } catch (e) {
                console.log(e);
            }
        }
        if (user.walletType === "metamask") {
            try {
                setUser(prevUser => {
                    return {
                        ...prevUser,
                        connected: false,
                    }
                });
            } catch (e) {
                console.log(e);
            }
        }
    }

    function isMetaMaskEnabled() {
        return typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask;
    }

    function isEthereumEnabled() {
        return typeof window.ethereum !== 'undefined';
    }

    function tryLogin() {
        if (isMetaMaskEnabled()) {
            initUserWallet("metamask");
        } else if (isEthereumEnabled()) {
            initUserWallet("walletlink");
        } else if (walletConnectProvider.connected) {
            initUserWallet("walletconnect");
        }
    }

    const notify = (message, autoClose, type) => toast(message, {autoClose, type});

    useEffect(() => {
        getRandomToken();
        initListeners();
        if (!MAINTENANCE) {
            //tryLogin();
        }
    }, []);

    useEffect(() => {
        if (user.walletAddress) {
            getUserInfo();
            getTokens();
        }
    }, [user.walletAddress]);

    useEffect(() => {
        AOS.init({
            once: true,
            disable: 'phone',
            duration: 750,
            easing: 'ease-out-quart',
        });
    });

    useEffect(() => {
        document.querySelector('html').style.scrollBehavior = 'auto'
        window.scroll({top: 0})
        document.querySelector('html').style.scrollBehavior = ''
    }, [location.pathname]); // triggered on route change

    if (MAINTENANCE) {
        return (
            <div className="flex align-middle justify-center flex-grow">
                <h1 style={{paddingTop: 50}}>Site under maintenance</h1>
            </div>
        );
    }

    return (
        <>
            <Switch>
                <Route exact path="/">
                    <Home notify={notify} user={user}/>
                </Route>

                {/* Main */}
                <Route path="/my-arcades">
                    <MyArcades notify={notify} user={user}/>
                </Route>
                <Route path="/drops">
                    <Drops notify={notify} user={user}/>
                </Route>
                <Route path="/about">
                    <About notify={notify} user={user}/>
                </Route>
                <Route path="/help">
                    <Help notify={notify} user={user}/>
                </Route>
                <Route path="/contact">
                    <Contact notify={notify} user={user}/>
                </Route>

                {/* Extra */}
                <Route path="/connect">
                    <Connect notify={notify} user={user}/>
                </Route>
                <Route path="/terms">
                    <Terms notify={notify} user={user}/>
                </Route>
                <Route path="/classics/traits-table">
                    <ClassicsTraits notify={notify} user={user}/>
                </Route>
                <Route path="/toadrunnerz/traits-table">
                    <ToadrunnerzTraits notify={notify} user={user}/>
                </Route>
                <Route path="/classics/rarity-tool">
                    <ClassicsRarity notify={notify} user={user}/>
                </Route>
                <Route path="/toadrunnerz/rarity-tool">
                    <ToadrunnerzRarity notify={notify} user={user}/>
                </Route>

                <Route path="*">
                    <PageNotFound notify={notify} user={user}/>
                </Route>
            </Switch>
        </>
    );
}

export default App;
