import React, {useState, useEffect} from 'react';

import Header from '../partials/Header';
import Footer from '../partials/Footer';
import {useHistory} from 'react-router-dom'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const inside_traits = [
    {
        title: "Alt Man",
        traits: [

            {
                name: "Elon Musk",
                image: require("../assets/images/classics/Elon Musk.svg").default,
                rarity: "5.15%",
                size: "50",
            },
            {
                name: "Pepe Frog",
                image: require("../assets/images/classics/Pepe Frog.svg").default,
                rarity: "11.70%",
                size: "80",
            },
            {
                name: "Trooper",
                image: require("../assets/images/classics/Trooper.svg").default,
                rarity: "20.01%",
                size: "55",
            },
            {
                name: "Scientist",
                image: require("../assets/images/classics/Scientist.svg").default,
                rarity: "30.62%",
                size: "57",
            },
            {
                name: "MetaMask",
                image: require("../assets/images/classics/MetaMask.svg").default,
                rarity: "32.52%",
                size: "65",
            },
        ],
    },
    {
        title: "Wall Color",
        traits: [

            {
                name: "Rainbow",
                image: require("../assets/images/classics/Rainbow.png").default,
                rarity: "1.66%",
                size: "70",
            },
            {
                name: "Gold",
                image: require("../assets/images/classics/Gold.png").default,
                rarity: "3.77%",
                size: "70",
            },
            {
                name: "Silver",
                image: require("../assets/images/classics/Silver.png").default,
                rarity: "6.45%",
                size: "70",
            },
            {
                name: "Bronze",
                image: require("../assets/images/classics/Bronze.png").default,
                rarity: "9.87%",
                size: "70",
            },
            {
                name: "White",
                image: require("../assets/images/classics/White.png").default,
                rarity: "10.48%",
                size: "70",
            },
            {
                name: "Blue",
                image: require("../assets/images/classics/Blue.png").default,
                rarity: "67.77%",
                size: "70",
            },
        ],
    },
    {
        title: "Monsters",
        traits: [

            {
                name: "CryptoPunks",
                image: require("../assets/images/classics/CryptoPunks-1.svg").default,
                rarity: "4.56%",
                size: "50",
            },
            {
                name: "Sail-o-bots",
                image: require("../assets/images/classics/Sail-o-bots-1.svg").default,
                rarity: "4.76%",
                size: "70",
            },
            {
                name: "Apes",
                image: require("../assets/images/classics/Bored Apes-1.svg").default,
                rarity: "4.89%",
                size: "50",
            },
            {
                name: "Penguins",
                image: require("../assets/images/classics/Pudgy Penguins-1.svg").default,
                rarity: "8.45%",
                size: "85",
            },
            {
                name: "Doge",
                image: require("../assets/images/classics/Doge-1.svg").default,
                rarity: "10.56%",
                size: "65",
            },
            {
                name: "McTrader",
                image: require("../assets/images/classics/McTrader-1.svg").default,
                rarity: "66.78%",
                size: "65",
            },
        ],
    },
    {
        title: "Power Up",
        traits: [

            {
                name: "Ethereum",
                image: require("../assets/images/classics/Ethereum.svg").default,
                rarity: "3.05%",
                size: "60",
            },
            {
                name: "Bitcoin",
                image: require("../assets/images/classics/Bitcoin.svg").default,
                rarity: "4.20%",
                size: "60",
            },
            {
                name: "Uniswap",
                image: require("../assets/images/classics/Uniswap.svg").default,
                rarity: "13.37%",
                size: "60",
            },
            {
                name: "Doge",
                image: require("../assets/images/classics/Doge-1.svg").default,
                rarity: "16.69%",
                size: "60",
            },
            {
                name: "Polygon",
                image: require("../assets/images/classics/Polygon.svg").default,
                rarity: "19.99%",
                size: "60",
            },
            {
                name: "YFI",
                image: require("../assets/images/classics/yearn.finance.svg").default,
                rarity: "42.69%",
                size: "60",
            },
        ],
    },
    {
        title: "Fruits",
        traits: [

            {
                name: "Ecstasy",
                image: require("../assets/images/classics/Ecstasy.svg").default,
                rarity: "6.66%",
                size: "60",
            },
            {
                name: "Mushroom",
                image: require("../assets/images/classics/Mushroom.svg").default,
                rarity: "7.77%",
                size: "51",
            },
            {
                name: "Sushi",
                image: require("../assets/images/classics/Sushi.svg").default,
                rarity: "8.88%",
                size: "60",
            },
            {
                name: "Polka",
                image: require("../assets/images/classics/Polka.svg").default,
                rarity: "9.99%",
                size: "60",
            },
            {
                name: "Mango",
                image: require("../assets/images/classics/Mango.svg").default,
                rarity: "19.19%",
                size: "75",
            },
            {
                name: "Cherry",
                image: require("../assets/images/classics/Cherry.svg").default,
                rarity: "47.50%",
                size: "55",
            },
        ],
    },
];

const outside_traits = [
    {
        title: "Background",
        traits: [
            {
                name: "Acid Oil",
                image: require("../assets/images/classics/AcidOil.png").default,
                rarity: "16.79%",
                size: "70",
            },
            {
                name: "Acid Chart",
                image: require("../assets/images/classics/AcidChart.png").default,
                rarity: "24.59%",
                size: "70",
            },
            {
                name: "Pure Black",
                image: require("../assets/images/classics/PureBlack.png").default,
                rarity: "26.45%",
                size: "70",
            },
            {
                name: "Off-White",
                image: require("../assets/images/classics/Off-White.png").default,
                rarity: "32.16%",
                size: "70",
            },
        ],
    },
    {
        title: "Game",
        traits: [
            {
                name: "Coin Boi",
                image: require("../assets/images/classics/CoinBoi.png").default,
                rarity: "18.79%",
                size: "95",
            },
            {
                name: "Alt Man",
                image: require("../assets/images/classics/AltMan.png").default,
                rarity: "21.00%",
                size: "65",
            },
            {
                name: "Token Hunter",
                image: require("../assets/images/classics/TokenHunter.png").default,
                rarity: "26.00%",
                size: "105",
            },
            {
                name: "Coin Bender",
                image: require("../assets/images/classics/CoinBender.png").default,
                rarity: "34.20%",
                size: "100",
            },
        ],
    },
    {
        title: "Artifact",
        traits: [
            {
                name: "China Town",
                image: require("../assets/images/classics/ChinaTown.png").default,
                rarity: "4.99%",
                size: "70",
            },
            {
                name: "Hattori Hanzō",
                image: require("../assets/images/classics/HattoriHanzon.png").default,
                rarity: "14.02%",
                size: "70",
            },
            {
                name: "Miami Weed",
                image: require("../assets/images/classics/MiamiWeed.png").default,
                rarity: "30.99%",
                size: "70",
            },
            {
                name: "Cartagena",
                image: require("../assets/images/classics/Cartagena.png").default,
                rarity: "49.99%",
                size: "70",
            },
        ],
    },
    {
        title: "Arcade Color",
        traits: [
            {
                name: "Crystal Nova",
                image: require("../assets/images/classics/ChinaTown.png").default,
                rarity: "0.88%",
                size: "70",
            },
            {
                name: "Rainbow Changa",
                image: require("../assets/images/classics/HattoriHanzon.png").default,
                rarity: "1.09%",
                size: "70",
            },
            {
                name: "Leaden Case",
                image: require("../assets/images/classics/MiamiWeed.png").default,
                rarity: "1.66%",
                size: "70",
            },
            {
                name: "Auric Bullion",
                image: require("../assets/images/classics/Cartagena.png").default,
                rarity: "1.77%",
                size: "70",
            },
            {
                name: "Sky Hallow",
                image: require("../assets/images/classics/ChinaTown.png").default,
                rarity: "1.79%",
                size: "70",
            },
            {
                name: "Blossom Hallow",
                image: require("../assets/images/classics/HattoriHanzon.png").default,
                rarity: "14.02%",
                size: "70",
            },
            {
                name: "Emerald Hallow",
                image: require("../assets/images/classics/MiamiWeed.png").default,
                rarity: "4.45%",
                size: "70",
            },
            {
                name: "Sunset Hallow",
                image: require("../assets/images/classics/Cartagena.png").default,
                rarity: "4.98%",
                size: "70",
            },
            {
                name: "Ag 925",
                image: require("../assets/images/classics/ChinaTown.png").default,
                rarity: "7.77%",
                size: "70",
            },
            {
                name: "Scarlet Twist",
                image: require("../assets/images/classics/HattoriHanzon.png").default,
                rarity: "8.65%",
                size: "70",
            },
            {
                name: "Onyx Bullion",
                image: require("../assets/images/classics/MiamiWeed.png").default,
                rarity: "13.44%",
                size: "70",
            },
            {
                name: "Emerald Glow",
                image: require("../assets/images/classics/Cartagena.png").default,
                rarity: "13.98%",
                size: "70",
            },
            {
                name: "Blossom Glow",
                image: require("../assets/images/classics/MiamiWeed.png").default,
                rarity: "14.66%",
                size: "70",
            },
            {
                name: "Sky Glow",
                image: require("../assets/images/classics/Cartagena.png").default,
                rarity: "22.69%",
                size: "70",
            },
        ],
    },
];

function ClassicsTraits(props) {

    const history = useHistory()

    const [tabs, setTabs] = useState(() => {
        return [
            {name: 'Outside Cadet', href: '#outside', current: true, data: outside_traits},
            {name: 'Inside Game', href: '#inside', current: false, data: inside_traits},
        ];
    });

    function getTraitsArray() {
        let toReturn = [];
        for (let i = 0; i < tabs.length; i++) {
            if (tabs[i].current) {
                toReturn = tabs[i].data;
            }
        }
        return toReturn;
    }

    useEffect(() => {
        return history.listen((location) => {
            const pathname = "#" + window.location.hash.substr(1);
            let newTabs = tabs.map(t => { return { ...t } });
            for (let i = 0; i < newTabs.length; i++) {
                newTabs[i].current = newTabs[i].href === pathname;
            }

            // if no "current" tab found set first tab as default
            if (newTabs.every(tab => tab.current === false)) {
                newTabs[0].current = true;
            }

            setTabs(newTabs);
        })
    }, [history])

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header user={props.user}/>

            {/*  Page content */}
            <main className="grow">

                <section className="relative pt-32">

                    <div className="mx-auto max-w-6xl px-6 sm:px-8">
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                                Select a tab
                            </label>
                            <select
                                id="tabs"
                                name="tabs"
                                className="block w-full pl-3 pr-10 py-2 bg-transparent text-base border-gray-600 dark:border-gray-300 focus:outline-none focus:ring-signature-900 focus:border-signature-900 sm:text-sm rounded-md"
                                defaultValue={tabs.find((tab) => tab.current).name}
                            >
                                {tabs.map((tab) => (
                                    <option key={tab.name}>{tab.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="hidden sm:block">
                            <div className="">
                                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                    {tabs.map((tab) => (
                                        <a
                                            key={tab.name}
                                            href={tab.href}
                                            className={classNames(
                                                tab.current
                                                    ? 'dark:border-signature-900 border-gray-900 text-gray-900 dark:text-signature-900'
                                                    : 'border-transparent dark:text-gray-300 text-gray-500 dark:hover:text-gray-700',
                                                'whitespace-nowrap py-4 px-1 border-b-2 font-medium'
                                            )}
                                            aria-current={tab.current ? 'page' : undefined}
                                        >
                                            {tab.name}
                                        </a>
                                    ))}
                                </nav>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="relative pb-20">
                    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 pb-20">
                        <div
                            className="pt-6 md:pt-22 text-3xl text-center justify-center items-center flex flex-col"
                            data-aos="fade-down">
                            <img className="mb-6"
                                 src={require('../assets/images/arcade_glasses.png').default}
                                 width="40" alt="Traits Glasses"/>
                            <h4 className="text-2xl text-center uppercase text-gray-900 dark:text-signature-900">Rarity
                                Traits</h4>
                        </div>
                    </div>
                    {getTraitsArray().map((data) => {
                            return (
                                <div key={data.title} className="max-w-6xl mx-auto pb-12">
                                    <h4 className="text-center uppercase pb-4">{data.title}</h4>
                                    <div className="flex flex-wrap justify-center" data-aos-id-team>
                                        {data.traits.map((trait) => {
                                            return (
                                                <div key={trait.name}
                                                     className={classNames(
                                                         data.href === "#outside"
                                                             ? 'w-1/2 sm:w-1/7 p-4 flex'
                                                             : 'w-1/2 sm:w-1/6 p-4 flex'
                                                     )}
                                                     className="w-1/2 sm:w-1/6 p-4 flex" data-aos="fade-down"
                                                     data-aos-anchor="[data-aos-id-team]">
                                                    <div style={{borderRadius: 16}}
                                                         className="flex flex-col bg-stone-200 dark:bg-arcade-100 flex-grow items-center justify-between py-12">
                                                        <img className="mb-6 mt-5 flex" src={trait.image}
                                                             width={trait.size} alt={trait.name}/>
                                                        <div className="flex text-center flex-col">
                                                            <p className="dark:text-gray-100 text-lg font-medium">{trait.name}</p>
                                                            <p className="dark:text-gray-300 text-sm font-medium">{trait.rarity}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            );
                        }
                    )}

                </section>


            </main>

            {/*  Site footer */}
            <Footer/>

        </div>
    );
}

export default ClassicsTraits;
