import React from 'react';

import Header from '../partials/Header';
import Footer from '../partials/Footer';

function ClassicsRarity(props) {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header user={props.user}/>

            {/*  Page content */}
            <main className="grow">

                <section className="relative">

                </section>


            </main>

            {/*  Site footer */}
            <Footer/>

        </div>
    );
}

export default ClassicsRarity;
