import React from 'react';

import Header from '../partials/Header';
import Footer from '../partials/Footer';

function About(props) {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header user={props.user}/>

            {/*  Page content */}
            <main className="grow">

                <section className="relative">

                    <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                        <div className="pt-32 pb-10 md:pt-40 text-3xl text-center" data-aos="fade-down">
                            <h2 className="text-center uppercase text-gray-900 dark:text-signature-900">About us</h2>
                        </div>
                    </div>
                    <div
                        className="flex max-w-6xl flex-row grid sm:grid-cols-2 justify-center items-center mx-auto my-6 pb-12">
                        <div className="mx-12">
                            <p style={{whiteSpace: "pre-line"}} className="dark:text-gray-300">During last Christmas
                                holiday a couple of good friends were having some drinks. A few beers into the
                                conversation we started talking about our childhood memories of our secret gaming
                                club and all the nights at the arcade. Our club was inspired by the movies we used
                                to watch like The Goonies, ET and Stand By Me. As club members, we endured
                                discovering new places in our home town to try out the latest arcade
                                machines.<br/><br/> Since
                                early 2021, we spent every night researching old retro arcades, that have ever been
                                recorded online. We’ve also been reading up about the history of early table board
                                machines, mapping out all the classic multicades and additionally studying board
                                geometry. <br/><br/>We were yet again, obsessed.</p>
                        </div>
                        <div className="order-first sm:order-2 mx-12 sm:mb-4 mb-0">
                            <img className="mb-6"
                                 src={require('../assets/images/arcade_about-1.png').default} width="100%"
                                 alt="Collage"/>
                        </div>
                    </div>
                </section>

                <section className="relative">

                    <div
                        className="flex max-w-6xl flex-row grid sm:grid-cols-2 justify-center items-center mx-auto my-6 pb-12">
                        <div className="mx-12 mb-6">
                            <img className="mb-6 "
                                 src={require('../assets/images/arcade_about-2.png').default} width="100%"
                                 alt="Collage"/>
                        </div>
                        <div className="mx-12">
                            <p style={{whiteSpace: "pre-line"}} className="dark:text-gray-300">Eventually, with the
                                entire
                                shared drive filled with inspirations, and roadmaps we decided it’s time to start
                                building. We started to put together sketches and technical drawings of the ultimate;
                                Arcade Classics NFT Series.<br/><br/> After months of testing, designing, development &
                                endless
                                iterations- we finally had a functional interactive NFT concept ready to be deployed and
                                shared with the NFT community at large!
                            </p>
                        </div>
                    </div>
                </section>

                <section className="relative">

                    <div
                        className="flex max-w-6xl flex-row grid sm:grid-cols-2 justify-center items-center mx-auto my-6 pb-12">
                        <div className="mx-12">
                            <p style={{whiteSpace: "pre-line"}} className="dark:text-gray-300">Join us on an adventure
                                where we take the classical arcade games into the future of
                                Non-funglible tokens.<br/><br/> By combining interactive games with amazing artforms
                                from both new talented artists, renowned creators & famous brands.<br/><br/>
                                We aspire our series of collectables to be fun, unique & addictive to play!
                            </p>
                        </div>
                        <div className="order-first sm:order-2 mx-12 sm:mb-4 mb-0">
                            <img className="mb-6"
                                 src={require('../assets/images/arcade_about-3.png').default} width="100%"
                                 alt="Collage"/>
                        </div>
                    </div>
                </section>

                <section className="relative pb-20">

                    <div className="relative max-w-7xl mx-auto px-4 sm:px-6">
                        <div className="pt-16 pb-20 md:pt-22 text-3xl text-center" data-aos="fade-down">
                            <h4 className="text-2xl text-center uppercase text-gray-900 dark:text-signature-900">The
                                Team</h4>
                        </div>
                    </div>
                    {/* Team members */}
                    <div className="max-w-6xl mx-auto pb-20">
                        <div className="flex flex-wrap justify-center -m-5 lg:-my-7" data-aos-id-team>

                            {/* Team member */}
                            <div className="w-1/2 sm:w-1/4 p-5 lg:py-7" data-aos="fade-down"
                                 data-aos-anchor="[data-aos-id-team]">
                                <div className="flex flex-col items-center">
                                    <img className="mb-2" src={require('../assets/images/team-1.png').default}
                                         width="100%" alt="Team member 01"/>
                                    <p className="dark:text-gray-100 text-lg font-medium">Toxicpoeth</p>
                                    <p className="dark:text-gray-300 text-sm font-medium">Scapegoat</p>
                                    <li className="mt-4 flex flex-row gap-2 items-center">
                                        <a className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                            target="_blank" rel="noreferrer" href="https://twitter.com/toxicpoeth" aria-label="Twitter">
                                            <svg
                                                className="w-8 h-8 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-black"
                                                viewBox="0 0 32 32"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z"/>
                                            </svg>
                                        </a>
                                    </li>
                                </div>
                            </div>

                            {/* Team member */}
                            <div className="w-1/2 sm:w-1/4 p-5 lg:py-7" data-aos="fade-down"
                                 data-aos-anchor="[data-aos-id-team]">
                                <div className="flex flex-col items-center">
                                    <img className="mb-2" src={require('../assets/images/team-2.png').default}
                                         width="100%" alt="Team member 01"/>
                                    <p className="dark:text-gray-100 text-lg font-medium">MOC</p>
                                    <p className="dark:text-gray-300 text-sm font-medium">Minister of Contracts</p>
                                    <li className="mt-4 flex flex-row gap-2 items-center">
                                        <a className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                           target="_blank" rel="noreferrer" href="https://twitter.com/MoC_Anft" aria-label="Twitter">
                                            <svg
                                                className="w-8 h-8 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-black"
                                                viewBox="0 0 32 32"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z"/>
                                            </svg>
                                        </a>
                                    </li>
                                </div>
                            </div>

                            {/* Team member */}
                            <div className="w-1/2 sm:w-1/4 p-5 lg:py-7" data-aos="fade-down"
                                 data-aos-anchor="[data-aos-id-team]">
                                <div className="flex flex-col items-center">
                                    <img className="mb-2" src={require('../assets/images/team-3.png').default}
                                         width="100%" alt="Team member 01"/>
                                    <p className="dark:text-gray-100 text-lg font-medium">Horiz0n</p>
                                    <p className="dark:text-gray-300 text-sm font-medium">Dev & Bug Hunter</p>
                                    <li className="mt-4 flex flex-row gap-2 items-center">
                                        <a className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                           target="_blank" rel="noreferrer" href="https://twitter.com/Horiz0nMinistry" aria-label="Twitter">
                                            <svg
                                                className="w-8 h-8 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-black"
                                                viewBox="0 0 32 32"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z"/>
                                            </svg>
                                        </a>
                                    </li>
                                </div>
                            </div>

                            {/* Team member */}
                            <div className="w-1/2 sm:w-1/4 p-5 lg:py-7" data-aos="fade-down"
                                 data-aos-anchor="[data-aos-id-team]">
                                <div className="flex flex-col items-center">
                                    <img className="mb-2" src={require('../assets/images/team-4.png').default}
                                         width="100%" alt="Team member 01"/>
                                    <p className="dark:text-gray-100 text-lg font-medium">ArcadeFlip</p>
                                    <p className="dark:text-gray-300 text-sm font-medium">Community & Project Leader</p>
                                    <li className="mt-4 flex flex-row gap-2 items-center">
                                        <a className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                           target="_blank" rel="noreferrer" href="https://twitter.com/SkalmanX" aria-label="Twitter">
                                            <svg
                                                className="w-8 h-8 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-black"
                                                viewBox="0 0 32 32"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z"/>
                                            </svg>
                                        </a>
                                    </li>
                                </div>
                            </div>

                            {/* Team member */}
                            <div className="w-1/2 sm:w-1/4 p-5 lg:py-7" data-aos="fade-down"
                                 data-aos-anchor="[data-aos-id-team]">
                                <div className="flex flex-col items-center">
                                    <img className="mb-2" src={require('../assets/images/team-5.png').default}
                                         width="100%" alt="Team member 01"/>
                                    <p className="dark:text-gray-100 text-lg font-medium">Yourmajesty</p>
                                    <p className="dark:text-gray-300 text-sm font-medium">Creative Dude</p>
                                    <li className="mt-4 flex flex-row gap-2 items-center">
                                        <a className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                           target="_blank" rel="noreferrer" href="https://twitter.com/arcade_nfts" aria-label="Twitter">
                                            <svg
                                                className="w-8 h-8 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-black"
                                                viewBox="0 0 32 32"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z"/>
                                            </svg>
                                        </a>
                                    </li>
                                </div>
                            </div>

                            {/* Team member */}
                            <div className="w-1/2 sm:w-1/4 p-5 lg:py-7" data-aos="fade-down"
                                 data-aos-anchor="[data-aos-id-team]">
                                <div className="flex flex-col items-center">
                                    <img className="mb-2" src={require('../assets/images/team-6.png').default}
                                         width="100%" alt="Team member 01"/>
                                    <p className="dark:text-gray-100 text-lg font-medium">Cling</p>
                                    <p className="dark:text-gray-300 text-sm font-medium">Game Dev</p>
                                    <li className="mt-4 flex flex-row gap-2 items-center">
                                        <a className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                           target="_blank" rel="noreferrer" href="https://twitter.com/arcade_nfts" aria-label="Twitter">
                                            <svg
                                                className="w-8 h-8 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-black"
                                                viewBox="0 0 32 32"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z"/>
                                            </svg>
                                        </a>
                                    </li>
                                </div>
                            </div>

                            {/* Team member */}
                            <div className="w-1/2 sm:w-1/4 p-5 lg:py-7" data-aos="fade-down"
                                 data-aos-anchor="[data-aos-id-team]">
                                <div className="flex flex-col items-center">
                                    <img className="mb-2" src={require('../assets/images/team-7.png').default}
                                         width="100%" alt="Team member 01"/>
                                    <p className="dark:text-gray-100 text-lg font-medium">ArcadeNFT_Nugg</p>
                                    <p className="dark:text-gray-300 text-sm font-medium">Collab Sourcer</p>
                                    <li className="mt-4 flex flex-row gap-2 items-center">
                                        <a className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                           target="_blank" rel="noreferrer" href="https://twitter.com/arcadenft" aria-label="Twitter">
                                            <svg
                                                className="w-8 h-8 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-black"
                                                viewBox="0 0 32 32"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z"/>
                                            </svg>
                                        </a>
                                    </li>
                                </div>
                            </div>

                            {/* Team member */}
                            <div className="w-1/2 sm:w-1/4 p-5 lg:py-7" data-aos="fade-down"
                                 data-aos-anchor="[data-aos-id-team]">
                                <div className="flex flex-col items-center">
                                    <img className="mb-2" src={require('../assets/images/team-8.png').default}
                                         width="100%" alt="Team member 01"/>
                                    <p className="dark:text-gray-100 text-lg font-medium">CrazySnake</p>
                                    <p className="dark:text-gray-300 text-sm font-medium">Marketing</p>
                                    <li className="mt-4 flex flex-row gap-2 items-center">
                                        <a className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                           target="_blank" rel="noreferrer" href="https://twitter.com/SnakCaptain" aria-label="Twitter">
                                            <svg
                                                className="w-8 h-8 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-black"
                                                viewBox="0 0 32 32"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z"/>
                                            </svg>
                                        </a>
                                    </li>
                                </div>
                            </div>

                            {/* Team member */}
                            <div className="w-1/2 sm:w-1/4 p-5 lg:py-7" data-aos="fade-down"
                                 data-aos-anchor="[data-aos-id-team]">
                                <div className="flex flex-col items-center">
                                    <img className="mb-2" src={require('../assets/images/team-9.png').default}
                                         width="100%" alt="Team member 01"/>
                                    <p className="dark:text-gray-100 text-lg font-medium">ShinyPants</p>
                                    <p className="dark:text-gray-300 text-sm font-medium">Pixel Pusher</p>
                                    <li className="mt-4 flex flex-row gap-2 items-center">
                                        <a className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                           target="_blank" rel="noreferrer" href="https://twitter.com/arcade_nfts" aria-label="Twitter">
                                            <svg
                                                className="w-8 h-8 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-black"
                                                viewBox="0 0 32 32"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z"/>
                                            </svg>
                                        </a>
                                    </li>
                                </div>
                            </div>

                            {/* Team member */}
                            <div className="w-1/2 sm:w-1/4 p-5 lg:py-7" data-aos="fade-down"
                                 data-aos-anchor="[data-aos-id-team]">
                                <div className="flex flex-col items-center">
                                    <img className="mb-2" src={require('../assets/images/team-10.png').default}
                                         width="100%" alt="Team member 01"/>
                                    <p className="dark:text-gray-100 text-lg font-medium">RDN</p>
                                    <p className="dark:text-gray-300 text-sm font-medium">3D</p>
                                    <li className="mt-4 flex flex-row gap-2 items-center">
                                        <a className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                           target="_blank" rel="noreferrer" href="https://twitter.com/arcade_nfts" aria-label="Twitter">
                                            <svg
                                                className="w-8 h-8 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-black"
                                                viewBox="0 0 32 32"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z"/>
                                            </svg>
                                        </a>
                                    </li>
                                </div>
                            </div>

                            {/* Team member */}
                            <div className="w-1/2 sm:w-1/4 p-5 lg:py-7" data-aos="fade-down"
                                 data-aos-anchor="[data-aos-id-team]">
                                <div className="flex flex-col items-center">
                                    <img className="mb-2" src={require('../assets/images/team-7.png').default}
                                         width="100%" alt="Team member 01"/>
                                    <p className="dark:text-gray-100 text-lg font-medium">Nagamash</p>
                                    <p className="dark:text-gray-300 text-sm font-medium">Discord Whiz</p>
                                    <li className="mt-4 flex flex-row gap-2 items-center">
                                        <a className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                           target="_blank" rel="noreferrer" href="https://twitter.com/arcadenfts" aria-label="Twitter">
                                            <svg
                                                className="w-8 h-8 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-black"
                                                viewBox="0 0 32 32"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z"/>
                                            </svg>
                                        </a>
                                    </li>
                                </div>
                            </div>

                            {/* Team member */}
                            <div className="w-1/2 sm:w-1/4 p-5 lg:py-7" data-aos="fade-down"
                                 data-aos-anchor="[data-aos-id-team]">
                                <div className="flex flex-col items-center">
                                    <img className="mb-2" src={require('../assets/images/team-4.png').default}
                                         width="100%" alt="Team member 01"/>
                                    <p className="dark:text-gray-100 text-lg font-medium">Satoshi</p>
                                    <p className="dark:text-gray-300 text-sm font-medium">Discord Manager</p>
                                    <li className="mt-4 flex flex-row gap-2 items-center">
                                        <a className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                           target="_blank" rel="noreferrer" href="https://twitter.com/versashotz" aria-label="Twitter">
                                            <svg
                                                className="w-8 h-8 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-black"
                                                viewBox="0 0 32 32"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z"/>
                                            </svg>
                                        </a>
                                    </li>
                                </div>
                            </div>

                            {/* Team member */}
                            <div className="w-1/2 sm:w-1/4 p-5 lg:py-7" data-aos="fade-down"
                                 data-aos-anchor="[data-aos-id-team]">
                                <div className="flex flex-col items-center">
                                    <img className="mb-2" src={require('../assets/images/team-12.png').default}
                                         width="100%" alt="Team member 01"/>
                                    <p className="dark:text-gray-100 text-lg font-medium">Plato</p>
                                    <p className="dark:text-gray-300 text-sm font-medium">Marketing Philosopher</p>
                                    <li className="mt-4 flex flex-row gap-2 items-center">
                                        <a className="flex justify-center items-center text-gray-900 bg-signature-900 dark:text-signature-500 dark:bg-gray-800 hover:underline hover:bg-signature-600 rounded-full transition duration-150 ease-in-out"
                                           target="_blank" rel="noreferrer" href="https://twitter.com/_CryptoPlato" aria-label="Twitter">
                                            <svg
                                                className="w-8 h-8 fill-current dark:fill-signature-900 hover:fill-gray-400 dark:hover:fill-black"
                                                viewBox="0 0 32 32"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z"/>
                                            </svg>
                                        </a>
                                    </li>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>


            </main>

            {/*  Site footer */}
            <Footer/>

        </div>
    );
}

export default About;
